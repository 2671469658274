import {
  EmptyAuthor,
  EmptyWork,
  EmptyEdition,
  Language,
  PhysicalFormat,
} from './Constant.js';

export function getLanguage(inputLanguage) {
  if (inputLanguage) {
    switch (inputLanguage.trim().toUpperCase()) {
      case Language.ARMENIAN:
        return Language.ARMENIAN;
      case Language.ENGLISH:
        return Language.ENGLISH;
      case Language.FRENCH:
        return Language.FRENCH;
      default:
        return Language.UNDEFINED;
    }
  }
  return Language.UNDEFINED;
}

export function getGradeLevel(inputGradeLevel) {
  if (inputGradeLevel) {
  }
}

export function getPhysicalFormat(inputFormat) {
  if (inputFormat) {
    switch (inputFormat.trim().toUpperCase()) {
      case PhysicalFormat.BOOK_PAPERBACK:
        return PhysicalFormat.BOOK_PAPERBACK;
      case PhysicalFormat.BOOK_HARDCOVER:
        return PhysicalFormat.BOOK_HARDCOVER;
      case PhysicalFormat.BOOK_LARGE_PRINT:
        return PhysicalFormat.BOOK_LARGE_PRINT;
      case PhysicalFormat.BOOK_ELECTRONIC:
        return PhysicalFormat.BOOK_ELECTRONIC;
      case PhysicalFormat.AUDIOBOOK_CD:
        return PhysicalFormat.AUDIOBOOK_CD;
      case PhysicalFormat.AUDIOBOOK_DOWNLOADABLE:
        return PhysicalFormat.AUDIOBOOK_DOWNLOADABLE;
      case PhysicalFormat.AUDIOBOOK_PRELOADED:
        return PhysicalFormat.AUDIOBOOK_PRELOADED;
      default:
        return PhysicalFormat.UNDEFINED;
    }
  }
  return PhysicalFormat.UNDEFINED;
}

export function convertAuthorToGradarak(openLibraryAuthor) {
  let author = { ...EmptyAuthor };
  if (openLibraryAuthor) {
    author.name = openLibraryAuthor.name;
    author.personalName = openLibraryAuthor.personal_name;
    author.openLibraryId = openLibraryAuthor.key;
    author.bio = truncateString(
      openLibraryAuthor.bio?.value || openLibraryAuthor.bio || '',
      /* maxLength */ 2000
    );
    author.birthDate = openLibraryAuthor.birth_date;
    author.deathDate = openLibraryAuthor.death_date;
  }
  return author;
}

export function convertWorkToGradarak(
  openLibraryWork,
  openLibraryWorkLanguageName,
  openLibraryEdition,
  openLibraryEditionLanguageName,
  authorList
) {
  let work = { ...EmptyWork };
  if (openLibraryWork) {
    work.title = openLibraryWork.title || '';
    work.subtitle = openLibraryWork.subtitle || '';
    work.description =
      openLibraryWork.description?.value ||
      openLibraryWork.description ||
      openLibraryEdition?.description?.value ||
      openLibraryEdition?.description ||
      '';
    work.description = truncateString(work.description, /* maxLength */ 2000);
    work.openLibraryId = openLibraryWork.key;

    if (openLibraryWorkLanguageName?.length) {
      work.originalLanguage = getLanguage(openLibraryWorkLanguageName);
    }

    if (
      (work.originalLanguage.length === 0 ||
        work.originalLanguage === Language.UNDEFINED) &&
      openLibraryEditionLanguageName?.length
    ) {
      work.originalLanguage = getLanguage(openLibraryEditionLanguageName);
    }

    const series = openLibraryEdition?.series?.length
      ? openLibraryEdition.series[0]
      : '';
    const seriesParts = parseSeries(series);
    work.series.title = seriesParts.seriesTitle;
    work.seriesItemNumber = seriesParts.seriesItemNumber;

    if (authorList.length === 0) {
      // add one empty author
      authorList.push({ ...EmptyAuthor });
    }
    work.authors = authorList;
  }

  return work;
}

export function convertEditionToGradarak(
  openLibraryEdition,
  openLibraryEditionLanguageName,
  openLibraryWorkCoverId,
  workList
) {
  let edition = { ...EmptyEdition };
  if (openLibraryEdition) {
    edition.openLibraryId = openLibraryEdition.key;
    edition.openLibraryCoverId = openLibraryEdition.covers?.length
      ? parseInt(openLibraryEdition.covers[0])
      : parseInt(openLibraryWorkCoverId) || 0;
    edition.publishDate = openLibraryEdition.publish_date || '';
    edition.publisher.name = openLibraryEdition.publishers?.length
      ? parsePublisher(openLibraryEdition.publishers[0])
      : '';
    edition.publishPlace = openLibraryEdition.publish_places?.length
      ? openLibraryEdition.publish_places[0]
      : '';
    const pageCount =
      openLibraryEdition.number_of_pages ||
      openLibraryEdition.pagination ||
      '0';
    edition.pageCount = isBookUnpaged(pageCount) ? 0 : parseInt(pageCount) || 0;
    edition.language = getLanguage(openLibraryEditionLanguageName);
    edition.title = openLibraryEdition.title || '';
    edition.subtitle = openLibraryEdition.subtitle || '';
    edition.fullTitle = openLibraryEdition.full_title || '';
    edition.isbn10 = openLibraryEdition.isbn_10
      ? openLibraryEdition.isbn_10[0]
      : '';
    edition.isbn13 = openLibraryEdition.isbn_13
      ? openLibraryEdition.isbn_13[0]
      : '';
    edition.physicalFormat = getPhysicalFormat(
      openLibraryEdition.physical_format
    );
    //edition.collection = openLibraryEdition.collection;
    edition.works = workList;
  }
  return edition;
}

function isBookUnpaged(pageCount) {
  return String(pageCount).includes('unpaged');
}

function parseSeries(series) {
  // Regular expression to match the non-numeric part and the numeric part
  const regex = /([^0-9]+)([0-9]+)/;
  const match = series.match(regex);

  if (match) {
    // Extract the parts
    const seriesTitle = match[1];
    const seriesItemNumber = match[2];
    return { seriesTitle, seriesItemNumber };
  } else {
    // If no match found, return empty strings
    return { seriesTitle: series, seriesItemNumber: '' };
  }
}

export function parsePublisher(publisherName) {
  //Remove ".", "inc", and "Inc" from the publisher name
  return publisherName.replace(/\./g, '').replace(/inc/gi, '').trim();
}

export function parseISBN(isbn) {
  // Remove any non-numeric characters but X from the ISBN
  const numericISBN = isbn.toString().replace(/[^0-9xX]/g, '');
  if (numericISBN.length > 10) {
    return numericISBN;
  }

  // Add leading zeros to make the total length of the ISBN 10 characters
  const paddedISBN = '0'.repeat(10 - numericISBN.length) + numericISBN;
  const finalISBN = paddedISBN.replace('x', 'X');

  return finalISBN;
}

export function truncateString(str, maxLength) {
  if (str.length > maxLength) {
    return str.slice(0, maxLength); // Truncate the string to maxLength characters
  }
  return str; // Return the original string if its length is less than or equal to maxLength
}

export function getOpenLibraryMediumCoverUrl(openLibraryCoverId) {
  return `https://covers.openlibrary.org/b/id/${openLibraryCoverId}-M.jpg`;
}

export function getOpenLibraryLargeCoverUrl(openLibraryCoverId) {
  return `https://covers.openlibrary.org/b/id/${openLibraryCoverId}-L.jpg`;
}
