import React, { Component } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import {
  Button,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
} from 'reactstrap';
import { backgroundStyle } from './InlineStyles.js';
import { EmptyUser } from './Constant.js';
import { loginUser, isValidApiResponse } from './OpenGradaranApi.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import { AppContext } from './AppContext.js';

/* This is a higher order component that
 *  inject a special prop to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouter;
}

class UserLoginForm extends React.Component {
  static contextType = AppContext;
  state = {
    user: { ...EmptyUser },
    showPassword: false, // State to toggle password visibility
  };

  constructor(props) {
    super(props);
    this.state = {
      user: { ...EmptyUser },
      showPassword: false, // Initialize password visibility state
    };
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
  }

  handleKeyDown = (event) => {
    if (event.key == 'Enter') {
      event.preventDefault(); // Prevent the default behavior of the "Enter" key
    }
  };

  handleUserChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let user = this.state.user;
    user[name] = value;

    this.setState((state) => {
      return { user };
    });
  }

  togglePasswordVisibility() {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  }

  async handleSubmit(event) {
    event.preventDefault();
    const { login } = this.context;
    const { user, showPassword } = this.state;

    var userResponse = await (await loginUser(user)).json();
    if (isValidApiResponse(userResponse)) {
      login(user, userResponse.token, userResponse.role);
      this.props.navigate('/');
    } else {
      //report error
    }
  }

  render() {
    const { t } = this.props;
    const { user, showPassword } = this.state;

    return (
      <div>
        <div style={backgroundStyle}>
          <Container
            className='auth-form'
            style={{ width: '30%', minWidth: '360px' }}
          >
            <div className='form-container'>
              <h2>{t('Log in')}</h2>
              <Form onSubmit={this.handleSubmit}>
                <Row>
                  <Col>
                    <FormGroup style={{ marginTop: '15px' }}>
                      <Label htmlFor='username'>{t('Username')}</Label>
                      <Input
                        type='email'
                        name='username'
                        id='username'
                        value={user.username}
                        required
                        placeholder='enter email you registered with'
                        onChange={this.handleUserChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup style={{ position: 'relative' }}>
                      <Label htmlFor='password'>{t('Password')}</Label>
                      <Input
                        type={showPassword ? 'text' : 'password'}
                        name='password'
                        id='password'
                        value={user.password}
                        required
                        placeholder='enter password'
                        onChange={this.handleUserChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                        style={{ paddingRight: '2rem' }} // Add padding to prevent text overlap
                      />
                      <button
                        type='button'
                        title='Toggle password visibility'
                        onClick={this.togglePasswordVisibility}
                        style={{
                          position: 'absolute',
                          right: '10px',
                          top: '55%',
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                          color: 'gray',
                        }}
                      >
                        <FontAwesomeIcon
                          icon={showPassword ? faEye : faEyeSlash}
                        />
                      </button>
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup style={{ marginTop: '20px' }}>
                  <Button color='primary' type='submit'>
                    {t('Log in')}
                  </Button>{' '}
                  <Button color='secondary' tag={Link} to='/'>
                    {t('Cancel')}
                  </Button>
                </FormGroup>
              </Form>

              {/* <div
                className='extra-links'
                style={{
                  textAlign: 'center',
                  marginTop: '20px',
                }}
              >
                <span style={{ marginRight: '5px' }}>
                  Don't have an account?
                </span>
                <Link to='/register'>Register</Link>
              </div> */}
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

const HOCUserLoginForm = withRouter(withTranslation()(UserLoginForm));
export default HOCUserLoginForm;
