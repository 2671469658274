import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import './styles/ColorSquareNavigation.css'; // Styling for the component
import { backgroundStyle } from './InlineStyles.js';
import { useTranslation } from 'react-i18next';

const Catalog = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div style={backgroundStyle}>
        <Container
          fluid
          style={{
            paddingTop: '10px',
            paddingLeft: '0px',
            paddingRight: '0px',
            // paddingBottom: '200px',
          }}
        >
          <Row className='color-squares-container'>
            <Col>
              <Link to='/editions' style={{ textDecoration: 'none' }}>
                <div className='color-square square-color-purple'>
                  <p>{t('Editions')}</p>
                </div>
              </Link>
            </Col>
            <Col>
              <Link to='/authors' style={{ textDecoration: 'none' }}>
                <div className='color-square square-color-green'>
                  <p>{t('Authors')}</p>
                </div>
              </Link>
            </Col>
            <Col>
              <Link to='/works' style={{ textDecoration: 'none' }}>
                <div className='color-square square-color-blue'>
                  <p>{t('Works')}</p>
                </div>
              </Link>
            </Col>

            {/* <Col>
              <Link to='/isbn/search' style={{ textDecoration: 'none' }}>
                <div className='color-square square-color-pink'>
                  <p>ISBN</p>
                </div>
              </Link>
            </Col> */}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Catalog;
