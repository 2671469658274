import React from 'react';
import * as XLSX from 'xlsx';
import { buildGradarakEdition } from './OpenLibraryApi.js';
import {
  postEditionWithWorkAndAuthors,
  isValidApiResponse,
} from './OpenGradaranApi.js';
import { parseISBN } from './OpenLibraryConverter.js';
import { Container } from 'reactstrap';
import { backgroundStyle } from './InlineStyles.js';
import { withTranslation } from 'react-i18next';
import Papa from 'papaparse';

const containerStyle = {
  width: '30%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center', // center items horizontally
};

class CatalogGenerator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      excelData: [],
      isLoading: false, // Loading state for API calls
    };
  }

  handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0]; // Assuming we're interested in the first sheet
      const sheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      var isLoading = true;
      this.setState({ excelData, isLoading });

      const mappingLogs = [];

      for (let i = 1; i < excelData.length; i++) {
        //for (let i = 1; i < 10; i++) {
        const row = excelData[i];
        // Assuming ISBN is in the third column (index 2)
        const author = row[0];
        const title = row[1];
        const isbn = parseISBN(row[2]);
        const donor = row[3];
        const misc = row[4];
        const school = row[6];
        const uuid = row[7];
        const bookDrive = row[8];
        var edition = null;
        var errorMsg = '';

        if (isbn === '-') {
          errorMsg = `Error fetching edition from open library ISBN = ${isbn}`;
          console.error(errorMsg);
        } else {
          try {
            edition = await buildGradarakEdition(isbn);
            if (edition && edition.openLibraryId) {
              // post edition
              var editionResponse = await postEditionWithWorkAndAuthors(
                edition
              );
              if (!isValidApiResponse(editionResponse)) {
                errorMsg = `Error posting edition ISBN = ${isbn} error: ${editionResponse.message}`;
                console.error(errorMsg);
              } else {
                edition = editionResponse;
              }
            } else {
              errorMsg = `Error fetching edition from open library ISBN = ${isbn}`;
              console.error(errorMsg);
            }
          } catch (error) {
            errorMsg = `Error fetching edition from open library ISBN = ${isbn}, ${error}`;
            console.error(errorMsg);
          }
        }
        mappingLogs.push({
          author,
          isbn,
          title,
          openLibraryTitle: edition?.title || 'null',
          misc,
          error: errorMsg || null,
          uuid,
          editionId: edition?.id || 0,
          donor,
          school,
          bookDrive,
        });
      }

      if (mappingLogs.length > 0) {
        const csv = Papa.unparse(mappingLogs);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'isbn_mapping_logs.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      isLoading = false;
      this.setState({ excelData, isLoading });
    };

    reader.readAsArrayBuffer(file);
  };

  render() {
    const { t } = this.props;
    let loadingView = null;
    if (this.state.isLoading) {
      loadingView = <p>Loading...</p>;
    }
    return (
      <div style={backgroundStyle}>
        <Container className='subform-container' style={containerStyle}>
          <div>
            <input type='file' onChange={this.handleFileUpload} />
          </div>
          <div>{loadingView}</div>
          {/* <table>
            <tbody>
              {this.state.excelData.map((row, index) => (
                <tr key={index}>
                  {row.map((cell, index) => (
                    <td key={index}>{cell}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table> */}
        </Container>
      </div>
    );
  }
}

export default withTranslation()(CatalogGenerator);
