import React, { Component } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { Container, Form, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import {
  postOrganization,
  postPersonProfile,
  postAddress,
  deleteAddress,
  isValidApiResponse,
} from './OpenGradaranApi';
import {
  EmptyOrganization,
  EmptyPersonProfile,
  EmptyAddress,
} from './Constant.js';
import { backgroundStyle, inputDisableStyle } from './InlineStyles.js';
import {
  SubFormTitle,
  DeleteButton,
  CloseButton,
  SaveButton,
  StatusFormGroup,
} from './utils/CustomViewUtil.js';
import { withTranslation } from 'react-i18next';
import { getFullName } from './utils/TextUtil.js';

/* This is a higher order component that
 *  inject a special prop to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  }
  return ComponentWithRouter;
}

class AddressForm extends Component {
  constructor(props) {
    super(props);

    const { organization, personProfile, address } = this.props;
    this.state = {
      organization: organization || EmptyOrganization,
      personProfile: personProfile || EmptyPersonProfile,
      address: address || EmptyAddress,
      isLoading: false, // Loading state for API calls
    };

    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.handleClose = this.handleClose.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.organization !== prevProps.organization ||
      this.props.address !== prevProps.address ||
      this.props.personProfile !== prevProps.personProfile
    ) {
      this.setState({
        organization: this.props.organization,
        personProfile: this.props.personProfile,
        address: this.props.address,
        isLoading: false,
      });
    }
  }

  async componentDidMount() {
    const address = this.props.location.state?.address || EmptyAddress;
    const organization =
      this.props.location.state?.organization || EmptyOrganization;
    var personProfile =
      this.props.location.state?.personProfile || EmptyPersonProfile;
    this.setState({
      organization,
      personProfile,
      address,
      isLoading: false,
    });
  }

  handleKeyDown = (event) => {
    if (event.key == 'Enter') {
      event.preventDefault(); // Prevent the default behavior of the "Enter" key
    }
  };

  handleAddressChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let address = { ...this.state.address };
    address[name] = value;
    this.setState({ address: address });
  }

  async handleSubmit(event) {
    event.preventDefault();
    const { organization, personProfile, address } = this.state;

    const addressResponse = await (await postAddress(address)).json();
    if (isValidApiResponse(addressResponse)) {
      if (organization.id) {
        organization.address = addressResponse;
        const organizationResponse = await (
          await postOrganization(organization)
        ).json();
        if (isValidApiResponse(organizationResponse)) {
          this.props.navigate(-1);
        } else {
          //report error
          const error = organizationResponse.message;
        }
      } else if (personProfile.id) {
        personProfile.address = addressResponse;
        const personProfileResponse = await (
          await postPersonProfile(personProfile)
        ).json();
        if (isValidApiResponse(personProfileResponse)) {
          this.props.navigate(-1);
        } else {
          //report error
          const error = personProfileResponse.message;
        }
      }
    } else {
      //report error
      // if (!organization.address?.id) {
      //   organization.address = null;
      // }
      // var error = addressResponse.message;
    }
  }

  handleClose(event) {
    event.preventDefault();
    this.props.navigate(-1);
  }

  async handleDelete(event) {
    event.preventDefault();

    // Display a confirmation dialog before proceeding with the delete action
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this address?'
    );

    if (!isConfirmed) {
      // If the user cancels, exit the function
      return;
    }

    const { address } = this.state;
    if (address.id) {
      try {
        var addressResponse = await (await deleteAddress(address.id)).json();
        if (isValidApiResponse(addressResponse)) {
          this.props.navigate(-1);
        } else {
          //report error
        }
      } catch (error) {
        // Handle network or other errors
        console.error('Error deleting address:', error);
      }
    } else {
      this.props.navigate(-1);
    }
  }

  render() {
    const { t } = this.props;
    const { organization, personProfile, address, isLoading } = this.state;
    const organizationTitle = organization.id ? (
      <SubFormTitle name={organization.name} resourceName={'Organization'} />
    ) : null;

    const personTitle = personProfile.id ? (
      <SubFormTitle
        name={getFullName(personProfile)}
        resourceName={'Person Profile'}
      />
    ) : null;

    if (!address) {
      return null;
    }
    return (
      <div style={backgroundStyle}>
        <Container className='subform-container'>
          <Row>
            {organizationTitle}
            {personTitle}
            <Form onSubmit={this.handleSubmit}>
              <Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='id'>{`${t('Address')}: ${t(
                        'Id'
                      )}`}</Label>
                      <Input
                        type='text'
                        name='id'
                        id='id'
                        value={address.id}
                        onChange={this.handleAddressChange}
                        readOnly
                        style={inputDisableStyle}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='buildingNumber'>{t('Building #')}</Label>
                      <Input
                        type='text'
                        name='buildingNumber'
                        id='buildingNumber'
                        value={address.buildingNumber}
                        onChange={this.handleAddressChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='street'>{t('Street Name')}</Label>
                      <Input
                        type='text'
                        name='street'
                        id='street'
                        value={address.street}
                        onChange={this.handleAddressChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor='streetAm'>{`${t('Street Name')} / ${t(
                        'in Armenian'
                      )}`}</Label>
                      <Input
                        type='text'
                        name='streetAm'
                        id='streetAm'
                        value={address.streetAm}
                        onChange={this.handleAddressChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='apartmentNumber'>
                        {t('Apartment #')}
                      </Label>
                      <Input
                        type='text'
                        name='apartmentNumber'
                        id='apartmentNumber'
                        value={address.apartmentNumber}
                        onChange={this.handleAddressChange}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='city'>{t('City')}</Label>
                      <Input
                        type='text'
                        name='city'
                        id='city'
                        value={address.city}
                        onChange={this.handleAddressChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor='cityAm'>{`${t('City')} / ${t(
                        'in Armenian'
                      )}`}</Label>
                      <Input
                        type='text'
                        name='cityAm'
                        id='cityAm'
                        value={address.cityAm}
                        onChange={this.handleAddressChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='province'>{t('Province')}</Label>
                      <Input
                        type='text'
                        name='province'
                        id='province'
                        value={address.province}
                        onChange={this.handleAddressChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor='provinceAm'>{`${t('Province')} / ${t(
                        'in Armenian'
                      )}`}</Label>
                      <Input
                        type='text'
                        name='provinceAm'
                        id='provinceAm'
                        value={address.provinceAm}
                        onChange={this.handleAddressChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='country'>{t('Country')}</Label>
                      <Input
                        type='text'
                        name='country'
                        id='country'
                        value={address.country}
                        onChange={this.handleAddressChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor='countryAm'>{`${t('Country')} / ${t(
                        'in Armenian'
                      )}`}</Label>
                      <Input
                        type='text'
                        name='countryAm'
                        id='countryAm'
                        value={address.countryAm}
                        onChange={this.handleAddressChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='postalCode'>{t('Postal Code')}</Label>
                      <Input
                        type='text'
                        name='postalCode'
                        id='postalCode'
                        value={address.postalCode}
                        onChange={this.handleAddressChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Row>
              <StatusFormGroup
                status={address.status}
                handleChange={this.handleChange}
              />
              <FormGroup>
                <SaveButton />
                <CloseButton onClick={this.handleClose} />
                <DeleteButton onClick={this.handleDelete} />
              </FormGroup>
            </Form>
          </Row>
        </Container>
      </div>
    );
  }
}
const HOCAddressForm = withRouter(withTranslation()(AddressForm));
export default HOCAddressForm;
