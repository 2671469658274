import React from 'react';
import { useTranslation } from 'react-i18next';

export const getText = (text, textAm, language) => {
  return language === 'en' ? text || textAm : textAm || text;
};

export const getSortField = (text, textAm, language) => {
  return language === 'en' ? text : textAm;
};

// Define an enum for case formats
export const CaseFormat = Object.freeze({
  LOWERCASE: 'lowercase',
  UPPERCASE: 'uppercase',
  CAPITALIZED: 'capitalized',
});

// Utility function that accepts the `t` function
export const createTranslator =
  (t) =>
  (text, caseFormat = CaseFormat.LOWERCASE) => {
    // Ensure the text is a string and default to lowercase for the lookup
    const translatedText = t((text || '').toLowerCase());

    // Apply the specified case format to the translated text
    switch (caseFormat) {
      case CaseFormat.UPPERCASE:
        return translatedText.toUpperCase();
      case CaseFormat.CAPITALIZED:
        return translatedText.charAt(0).toUpperCase() + translatedText.slice(1);
      case CaseFormat.LOWERCASE:
      default:
        return translatedText.toLowerCase();
    }
  };

// React Hook for functional components
export const useTranslate = () => {
  const { t } = useTranslation();
  return createTranslator(t);
};

// export const tToLowerCase = (text) => {
//   return translate(text, CaseFormat.LOWERCASE);
// };

// export const tToUpperCase = (text) => {
//   return translate(text, CaseFormat.UPPERCASE);
// };

// export const tToCapitalized = (text) => {
//   return translate(text, CaseFormat.CAPITALIZED);
// };
