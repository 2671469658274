import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import './styles/ColorSquareNavigation.css';
import { backgroundStyle, headerAlignStyle } from './InlineStyles.js';
import { useTranslation } from 'react-i18next';
import { getText } from './utils/TranslationUtil.js';
import { AppContext } from './AppContext.js';

const HomeColorSquareNavigation = () => {
  const organizationId = 1;
  const { t } = useTranslation();
  const { language } = useContext(AppContext);
  return (
    <div>
      <div style={{ ...backgroundStyle }}>
        <Container
          fluid
          style={{
            paddingTop: '10px',
            paddingLeft: '5px',
            // paddingRight: '0px',
            // paddingBottom: '200px',
          }}
        >
          <Row>
            <Col xs={12}>
              <div style={headerAlignStyle}>
                <h5>
                  {getText(
                    'Our goal is to provide extracurricular English reading materials for regional schools and libraries in Armenia',
                    'Մեր նպատակն է արտադասարանական անգլերեն ընթերցանության նյութեր տրամադրել Հայաստանի մարզային դպրոցներին և գրադարաններին',
                    language
                  )}
                </h5>
              </div>
            </Col>
          </Row>
          <Row className='color-squares-container'>
            <Col>
              <Link
                to={`/organizations/${organizationId}/donation-events`}
                style={{ textDecoration: 'none' }}
              >
                <div className='color-square square-color-green'>
                  <p>{t('Events')}</p>
                </div>
              </Link>
            </Col>
            <Col>
              <Link
                to={`/organizations/${organizationId}/donations`}
                style={{ textDecoration: 'none' }}
              >
                <div className='color-square square-color-yellow'>
                  <p>{t('Donations')}</p>
                </div>
              </Link>
            </Col>
            <Col>
              <Link to='/organizations' style={{ textDecoration: 'none' }}>
                <div className='color-square square-color-blue'>
                  <p>{t('Organizations')}</p>
                </div>
              </Link>
            </Col>
            <Col>
              <Link to='/catalog' style={{ textDecoration: 'none' }}>
                <div className='color-square square-color-purple'>
                  <p>{t('Catalog')}</p>
                </div>
              </Link>
            </Col>
            {/* <Col>
              <Link to='/book-generator' style={{ textDecoration: 'none' }}>
                <div className='color-square square-color-purple'>
                  <p>{t('Book Generator')}</p>
                </div>
              </Link>
            </Col> */}
            {/* <Col>
              <Link to='/person-profiles' style={{ textDecoration: 'none' }}>
                <div className='color-square square-color-purple'>
                  <p>{t('Person Profiles')}</p>
                </div>
              </Link>
            </Col> */}
            {/* <Col>
              <Link to='/isbn' style={{ textDecoration: 'none' }}>
                <div className='color-square square-color-purple'>
                  <p>{t('ISBN')}</p>
                </div>
              </Link>
            </Col> */}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default HomeColorSquareNavigation;
