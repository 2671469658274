import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPencilSquare,
  faGlobe,
  faEnvelope,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import {
  EmptyContact,
  EmptyOrganization,
  EmptyPersonProfile,
  ContactTypeOptions,
  ContactType,
  getLabelByOptionValue,
  canEditContent,
} from './Constant.js';
import { withTranslation } from 'react-i18next';
import { AppContext } from './AppContext.js';

/* This is a higher order component that
 *  inject a special prop to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouter;
}

class ContactView extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);

    const { organization, personProfile, contact } = this.props;
    this.state = {
      organization: organization || EmptyOrganization,
      personProfile: personProfile || EmptyPersonProfile,
      contact: contact || EmptyContact,
    };
    this.handleEditClick = this.handleEditClick.bind(this);
  }

  handleEditClick(event) {
    event.preventDefault();
    console.log('Edit clicked!');
    const { organization, personProfile, contact } = this.state;
    this.props.navigate(`/contacts/${contact.id}`, {
      state: { organization, personProfile, contact },
    });
  }

  render() {
    const { t } = this.props;
    const { appState } = this.context;
    const { organization, personProfile, contact } = this.state;
    if (!contact) {
      return null;
    }

    const contactTypeLabel = getLabelByOptionValue(
      ContactTypeOptions,
      contact.type
    );

    const renderIcon = () => {
      switch (contact.type) {
        case ContactType.WEBSITE:
          return <FontAwesomeIcon icon={faGlobe} />;
        case ContactType.EMAIL:
          return <FontAwesomeIcon icon={faEnvelope} />;
        case ContactType.PHONE:
          return <FontAwesomeIcon icon={faPhone} />;
        case ContactType.FACEBOOK:
          return <FontAwesomeIcon icon={faFacebook} />;
        default:
          return null;
      }
    };

    return (
      <Container className='contact-view-container'>
        {/* Edit icon at the upper right corner */}
        {canEditContent() && (
          <div className='edit-pencil-container'>
            <a href='' onClick={this.handleEditClick}>
              <FontAwesomeIcon
                icon={faPencilSquare}
                className='edit-pencil-small'
              />
            </a>
          </div>
        )}
        <p>
          {/* {contactTypeLabel || contact.type}: {contact.value} */}
          {renderIcon() || contactTypeLabel || contact.type}:{' '}
          {/* {contact.type === ContactType.WEBSITE ? (
            <a href={contact.value} target='_blank' rel='noopener noreferrer'>
              {contact.value}
            </a>
          ) : (
            contact.value
          )} */}
          {(() => {
            switch (contact.type) {
              case ContactType.FACEBOOK:
              case ContactType.WEBSITE:
              case ContactType.SPYUR:
                return (
                  <a
                    href={contact.value}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {contact.value}
                  </a>
                );
              case ContactType.EMAIL:
                return (
                  <a
                    href={contact.value}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {contact.value}
                  </a>
                );
              default:
                return contact.value;
            }
          })()}
        </p>
      </Container>
    );
  }
}
const HOCContactView = withRouter(withTranslation()(ContactView));
export default HOCContactView;
