import React, { Component } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import {
  getEdition,
  getWorksByEditionId,
  isValidApiResponse,
} from './OpenGradaranApi.js';
import { EmptyEdition, EmptyWork, canEditContent } from './Constant.js';
import { backgroundStyle } from './InlineStyles.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faStar } from '@fortawesome/free-solid-svg-icons';
import './styles/Edition.css';
import {
  ViewTitle,
  BackButton,
  BookCoverBig,
  getAuthorList,
  Pair,
} from './utils/CustomViewUtil.js';
import { renderWithDelimiter } from './utils/TextUtil.js';
import { withTranslation } from 'react-i18next';
import openlibrary from './images/openlibrary-logo-tighter.svg';
import { AppContext } from './AppContext.js';
import { createTranslator, CaseFormat } from './utils/TranslationUtil.js';

/* This is a higher order component that
 *  inject a special prop to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  }
  return ComponentWithRouter;
}

class EditionPage extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);

    this.state = {
      edition: { ...EmptyEdition },
      works: [],
    };
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  async componentDidMount() {
    const id = this.props.params.id;
    var edition = this.props.location.state?.edition;
    if (!edition && id) {
      const editionResp = await (await getEdition(id)).json();
      if (isValidApiResponse(editionResp)) {
        edition = editionResp;
      } else {
        // handle error
      }
    }
    var works;
    if (edition.id) {
      const workPageResponse = await (
        await getWorksByEditionId(edition.id, 0)
      ).json();

      if (workPageResponse.message == null) {
        works = workPageResponse.data.page;
      } else {
        works = [];
        // handle error
      }
    }
    this.setState({ edition: edition || EmptyEdition, works });
  }

  handleEditClick(event) {
    event.preventDefault();
    console.log('Icon clicked!');
    const { edition } = this.state;
    this.props.navigate('/editions/' + edition.id);
  }

  handleGoBack = () => {
    this.props.navigate(-1); // Use history.goBack() to navigate back
  };

  render() {
    const { t } = this.props;
    const translate = createTranslator(t);
    const { appState } = this.context;
    const { edition, works } = this.state;
    const firstWork = works && works.length > 0 ? works[0] : EmptyWork;
    const authorList = getAuthorList(firstWork);
    const publisherAttrs = [
      edition.publisher?.name,
      edition.publishPlace,
      edition.publishDate,
    ];

    return (
      <div>
        <div style={backgroundStyle}>
          <Container
            className='light-blue-white'
            style={{ width: '70%', maxWidth: '820px' }}
          >
            <div
              style={{
                position: 'relative',
                padding: '30px',
              }}
            >
              <Row>
                <Col>
                  <BookCoverBig edition={edition} />
                </Col>
                <Col>
                  <ViewTitle name={edition.title} resourceName={'Edition'} />
                  <Pair label={'ISBN-13'} value={edition.isbn13} />
                  <Pair label={'ISBN-10'} value={edition.isbn10} />
                  <Pair
                    label={'Language'}
                    value={translate(edition.language, CaseFormat.CAPITALIZED)}
                  />
                  {edition.pageCount > 0 && (
                    <Pair label={'Page count'} value={edition.pageCount} />
                  )}
                  <p>
                    {renderWithDelimiter(
                      publisherAttrs,
                      <FontAwesomeIcon icon={faStar} />
                    )}
                  </p>
                  {firstWork.series && (
                    <Pair label={'Series'} value={firstWork.series.title} />
                  )}
                  <p>
                    <Link
                      to={`/editions/${edition.id}/works`}
                      state={{ edition }}
                      className='custom-green'
                    >
                      {t('Works')}
                    </Link>
                  </p>

                  {edition.openLibraryId && (
                    <p>
                      <Link
                        to={`https://openlibrary.org/${edition.openLibraryId}`}
                        className='custom-green'
                      >
                        <img
                          src={openlibrary}
                          alt='Open Library'
                          className='external-logo'
                        />
                      </Link>
                    </p>
                  )}

                  {edition.worldCat && (
                    <p>
                      <Link
                        to={edition.worldCat}
                        className='custom-green'
                        style={{ marginLeft: '5px' }}
                      >
                        WorldCat
                      </Link>
                    </p>
                  )}
                  <p>
                    <Link
                      to={`/editions/${edition.id}/books`}
                      state={{ edition }}
                      className='custom-green'
                    >
                      {t('Availability').toUpperCase()}
                    </Link>
                  </p>
                </Col>
                {/* <Col></Col> */}
              </Row>
              <Row>
                <div style={{ position: 'relative' }}>
                  <p>{firstWork.description}</p>
                </div>
                {authorList && authorList.length > 0 && <p>by {authorList}</p>}
                <p>
                  <BackButton onClick={this.handleGoBack} />
                </p>
              </Row>

              {/* Edit icon at the upper right corner */}
              {canEditContent() && (
                <div className='edit-pencil-container'>
                  <a href='' onClick={this.handleEditClick}>
                    <FontAwesomeIcon
                      icon={faPencilAlt}
                      className='edit-pencil'
                    />
                  </a>
                </div>
              )}
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
const HOCEditionPage = withRouter(withTranslation()(EditionPage));
export default HOCEditionPage;
