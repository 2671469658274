import {
  convertAuthorToGradarak,
  convertWorkToGradarak,
  convertEditionToGradarak,
} from './OpenLibraryConverter.js';

import { EmptyWorkWithAuthors, EmptyEditionWithWorks } from './Constant.js';

export async function buildGradarakAuthorListFromEdition(openLibraryEdition) {
  const editionAuthorList = [];
  if (openLibraryEdition?.authors?.length > 0) {
    for (const authorLink of openLibraryEdition.authors) {
      const openLibraryAuthor = await (
        await fetch(getOpenLibraryAuthorUrl(authorLink.key))
      ).json();

      if (openLibraryAuthor) {
        editionAuthorList.push(convertAuthorToGradarak(openLibraryAuthor));
      }
    }
  }
  return editionAuthorList;
}

export async function buildGradarakAuthorListFromWork(openLibraryWork) {
  const workAuthorList = [];
  if (openLibraryWork?.authors?.length > 0) {
    for (const authorLink of openLibraryWork.authors) {
      const openLibraryAuthor = await (
        await fetch(getOpenLibraryAuthorUrl(authorLink.author.key))
      ).json();
      if (openLibraryAuthor) {
        workAuthorList.push(convertAuthorToGradarak(openLibraryAuthor));
      }
    }
  }
  return workAuthorList;
}

export async function buildGradarakWorkList(
  openLibraryWorkList,
  openLibraryEdition,
  openLibraryEditionLanguageName,
  editionAuthorList
) {
  const workList = [];
  if (openLibraryWorkList?.length > 0) {
    for (const openLibraryWork of openLibraryWorkList) {
      if (openLibraryWork) {
        const workAuthorList = await buildGradarakAuthorListFromWork(
          openLibraryWork
        );
        const openLibraryWorkLanguage = await getOpenLibraryWorkLanguage(
          openLibraryWork
        );

        const work = convertWorkToGradarak(
          openLibraryWork,
          openLibraryWorkLanguage,
          openLibraryEdition,
          openLibraryEditionLanguageName,
          workAuthorList?.length > 0 ? workAuthorList : editionAuthorList
        );
        workList.push(work);
      }
    }
  }
  if (!workList?.length) {
    //const work = { ...EmptyWork };
    workList.push({ ...EmptyWorkWithAuthors });
  }
  return workList;
}

export function getEditionCover(openLibraryWorkList) {
  if (openLibraryWorkList?.length) {
    for (const openLibraryWork of openLibraryWorkList) {
      if (openLibraryWork?.covers?.length > 0) {
        return openLibraryWork.covers[0];
      }
    }
  }
  return '';
}

export async function buildGradarakEdition(isbn) {
  try {
    const openLibraryEdition = await getOpenLibraryEdition(isbn);
    // if (!openLibraryEditionResponse.ok) {
    //   throw new Error(`HTTP error: ${openLibraryEditionResponse.status}`);
    // }
    // const openLibraryEdition = await openLibraryEditionResponse.json();
    console.log(openLibraryEdition.title);
    const editionAuthorList = await buildGradarakAuthorListFromEdition(
      openLibraryEdition
    );

    const openLibraryEditionLanguageName = await getOpenLibraryEditionLanguage(
      openLibraryEdition
    );

    const openLibraryWorkList = await getOpenLibraryWorks(openLibraryEdition);
    const workList = await buildGradarakWorkList(
      openLibraryWorkList,
      openLibraryEdition,
      openLibraryEditionLanguageName,
      editionAuthorList
    );
    let openLibraryWorkCoverId = getEditionCover(openLibraryWorkList);

    return convertEditionToGradarak(
      openLibraryEdition,
      openLibraryEditionLanguageName,
      openLibraryWorkCoverId,
      workList
    );
  } catch (error) {
    console.error(`Could not get openLibraryEdition: ${error}`);
  }
  // return an empty edition
  return { ...EmptyEditionWithWorks };
}

// export function buildEmptyGradarakEdition() {
//   return {
//     ...EmptyEdition,
//     works: [{ ...EmptyWork, authors: [{ ...EmptyAuthor }] }],
//   };
// }

// export function buildEmptyGradarakWork() {
//   return { ...EmptyWork, authors: [{ ...EmptyAuthor }] }
// }

export async function getOpenLibraryEdition(isbn) {
  return (await fetch(getOpenLibraryIsbnUrl(isbn))).json();
}

export async function getOpenLibraryWorks(openLibraryEdition) {
  const openLibraryWorkList = [];

  if (openLibraryEdition?.works?.length > 0) {
    for (const workLink of openLibraryEdition.works) {
      const openLibraryWork = await (
        await fetch(getOpenLibraryWorkUrl(workLink.key))
      ).json();

      if (openLibraryWork) {
        openLibraryWorkList.push(openLibraryWork);
      }
    }
  }

  return openLibraryWorkList;
}

export async function getOpenLibraryWorkLanguage(openLibraryWork) {
  return openLibraryWork.languages?.length > 0
    ? (
        await (
          await fetch(
            'https://openlibrary.org' +
              openLibraryWork.languages[0].key +
              '.json'
          )
        ).json()
      )?.name
    : '';
}

export async function getOpenLibraryEditionLanguage(openLibraryEdition) {
  return openLibraryEdition?.languages?.length > 0
    ? (
        await (
          await fetch(
            'https://openlibrary.org' +
              openLibraryEdition.languages[0].key +
              '.json'
          )
        ).json()
      )?.name
    : '';
}

function getOpenLibraryIsbnUrl(isbn) {
  return 'https://openlibrary.org/isbn/' + isbn + '.json';
}

function getOpenLibraryWorkUrl(workKey) {
  return 'https://openlibrary.org' + workKey + '.json';
}

function getOpenLibraryAuthorUrl(authorKey) {
  return 'https://openlibrary.org' + authorKey + '.json';
}
