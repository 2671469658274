import './styles/App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React, { useEffect, useContext } from 'react';
import AppNavbar from './AppNavbar.js';
import AppFooter from './AppFooter.js';
import AddressForm from './AddressForm.js';

import ArmenianLibraryProject from './ArmenianLibraryProject.js';

import ArmeniaMapWithLibraries from './ArmeniaMapWithLibraries.js';

import AuthorListPaginated from './AuthorListPaginated.js';
import AuthorForm from './AuthorForm.js';
import AuthorPage from './AuthorPage.js';

import BookListPaginated from './BookListPaginated.js';
import BookForm from './BookForm.js';
import BookPage from './BookPage.js';
import BarcodeScanner from './BarcodeScanner.js';

import ContactForm from './ContactForm.js';
import Catalog from './Catalog.js';

import DonationEventListPaginated from './DonationEventListPaginated.js';
import DonationEventForm from './DonationEventForm.js';
import DonationEventView from './DonationEventView.js';

import DonationListPaginated from './DonationListPaginated.js';
import DonationForm from './DonationForm.js';
import DonationView from './DonationView.js';

import EditionListPaginated from './EditionListPaginated.js';
import EditionIsbnForm from './EditionIsbnForm.js';
import EditionForm from './EditionForm.js';
import EditionPage from './EditionPage.js';

import CatalogGeneratorr from './CatalogGenerator.js';

import IsbnReader from './IsbnReader.js';

import HomeColorSquareNavigation from './HomeColorSquareNavigation.js';

import OrganizationListPaginated from './OrganizationListPaginated.js';
import OrganizationForm from './OrganizationForm.js';
import OrganizationPage from './OrganizationPage.js';

import PersonProfileListPaginated from './PersonProfileListPaginated.js';
import PersonProfileForm from './PersonProfileForm.js';
import PersonProfilePage from './PersonProfilePage.js';

import PublisherListPaginated from './PublisherListPaginated.js';
import PublisherForm from './PublisherForm.js';
import PublisherPage from './PublisherPage.js';

import SeriesListPaginated from './SeriesListPaginated.js';
import SeriesForm from './SeriesForm.js';
import SeriesPage from './SeriesPage.js';

import ShippingBoxListPaginated from './ShippingBoxListPaginated.js';
import ShippingBoxForm from './ShippingBoxForm.js';
import ShippingBoxPage from './ShippingBoxPage.js';

import WorkListPaginated from './WorkListPaginated.js';
import WorkForm from './WorkForm.js';
import WorkPage from './WorkPage.js';
import AboutUsPage from './AboutUsPage.js';
import UserLoginForm from './UserLoginForm.js';
import UserRegisterForm from './UserRegisterForm.js';
import CatalogGenerator from './CatalogGenerator.js';
import BookGenerator from './BookGenerator.js';
import { AppContext } from './AppContext.js';

const App = () => {
  const { checkTokenOnLoad } = useContext(AppContext);
  useEffect(() => {
    checkTokenOnLoad();
  }, []);
  return (
    <div className='App'>
      <BrowserRouter>
        <div className='page-container'>
          <AppNavbar />
          <main>
            <Routes>
              <Route path='/register' element={<UserRegisterForm />} />
              <Route path='/login' element={<UserLoginForm />} />
              <Route path='/alp' element={<ArmenianLibraryProject />} />
              <Route path='/about-us' element={<AboutUsPage />} />
              <Route path='/armenia' element={<ArmeniaMapWithLibraries />} />

              <Route exact path='/' element={<HomeColorSquareNavigation />} />

              <Route path='/isbn/search' element={<IsbnReader />} />
              {/* <Route path='/isbn/search' element={<BarcodeScanner />} /> */}
              <Route path='/isbn/:isbn' element={<EditionIsbnForm />} />
              <Route path='/isbn' element={<CatalogGenerator />} />
              <Route path='/book-generator' element={<BookGenerator />} />

              <Route path='/catalog' element={<Catalog />} />

              <Route
                path='/authors'
                exact={true}
                element={<AuthorListPaginated />}
              />
              <Route path='/authors/:id' element={<AuthorForm />} />
              <Route path='/authors/page/:id' element={<AuthorPage />} />

              <Route
                path='/editions'
                exact={true}
                element={<EditionListPaginated />}
              />
              <Route
                path='/works/:id/editions'
                element={<EditionListPaginated />}
              />
              <Route
                path='/publishers/:id/editions'
                element={<EditionListPaginated />}
              />
              <Route
                path='/editions/search/:isbn'
                exact={true}
                element={<EditionListPaginated />}
              />
              <Route
                path='/editions/:id'
                exact={true}
                element={<EditionForm />}
              />
              <Route path='/editions/page/:id' element={<EditionPage />} />

              <Route
                path='/works'
                exact={true}
                element={<WorkListPaginated />}
              />
              <Route
                path='/authors/:id/works'
                element={<WorkListPaginated />}
              />
              <Route
                path='/editions/:id/works'
                element={<WorkListPaginated />}
              />
              <Route path='/series/:id/works' element={<WorkListPaginated />} />
              <Route path='/works/:id' element={<WorkForm />} />
              <Route path='/works/page/:id' element={<WorkPage />} />

              <Route
                path='/organizations'
                exact={true}
                element={<OrganizationListPaginated />}
              />
              <Route
                path='/organizations/:id'
                exact={true}
                element={<OrganizationForm />}
              />
              <Route
                path='/organizations/page/:id'
                element={<OrganizationPage />}
              />

              <Route
                path='/organizations/alp-participants'
                element={<OrganizationListPaginated />}
              />

              <Route
                path='/organizations/:id/books'
                element={<BookListPaginated />}
              />

              <Route path='/libraries/:id' element={<OrganizationForm />} />
              <Route
                path='/libraries/page/:id'
                element={<OrganizationPage />}
              />

              <Route
                path='/editions/:id/books'
                element={<BookListPaginated />}
              />
              <Route path='/books' element={<BookListPaginated />} />
              <Route path='/books/:id' element={<BookForm />} />
              <Route path='/books/page/:id' element={<BookPage />} />
              <Route path='/catalog/:uuid' element={<BookPage />} />

              <Route path='/contacts/:id' element={<ContactForm />} />

              <Route path='/addresses/:id' element={<AddressForm />} />

              <Route
                path='/organizations/:id/donation-events'
                element={<DonationEventListPaginated />}
              />
              <Route
                path='/donation-events/:id'
                element={<DonationEventForm />}
              />
              <Route
                path='/donation-events/page/:id'
                element={<DonationEventView />}
              />

              <Route
                path='/organizations/:id/donations'
                element={<DonationListPaginated />}
              />
              <Route
                path='/donation-events/:id/donations'
                element={<DonationListPaginated />}
              />
              <Route
                path='/shipping-boxes/:id/donations'
                element={<DonationListPaginated />}
              />
              <Route path='/donations/:id' element={<DonationForm />} />
              <Route path='/donations/page/:id' element={<DonationView />} />

              <Route
                path='/organizations/:id/shipping-boxes'
                element={<ShippingBoxListPaginated />}
              />
              <Route
                path='/donation-events/:id/shipping-boxes'
                element={<ShippingBoxListPaginated />}
              />
              <Route path='/shipping-boxes/:id' element={<ShippingBoxForm />} />
              <Route
                path='/shipping-boxes/page/:id'
                element={<ShippingBoxPage />}
              />

              <Route
                path='/person-profiles'
                exact={true}
                element={<PersonProfileListPaginated />}
              />
              <Route
                path='/person-profiles/:id'
                element={<PersonProfileForm />}
              />
              <Route
                path='/person-profiles/page/:id'
                element={<PersonProfilePage />}
              />

              <Route
                path='/publishers'
                exact={true}
                element={<PublisherListPaginated />}
              />
              <Route path='/publishers/:id' element={<PublisherForm />} />
              <Route path='/publishers/page/:id' element={<PublisherPage />} />

              <Route
                path='/series'
                exact={true}
                element={<SeriesListPaginated />}
              />
              <Route path='/series/:id' element={<SeriesForm />} />
              <Route path='/series/page/:id' element={<SeriesPage />} />
            </Routes>
          </main>
          <AppFooter />
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
