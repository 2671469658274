import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faPencilSquare } from '@fortawesome/free-solid-svg-icons';
import {
  EmptyAddress,
  EmptyOrganization,
  EmptyPersonProfile,
  canEditContent,
} from './Constant.js';
import { renderWithDelimiter } from './utils/TextUtil.js';
import { withTranslation } from 'react-i18next';
import { AppContext } from './AppContext.js';
import { getText } from './utils/TranslationUtil.js';

/* This is a higher order component that
 *  inject a special prop to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouter;
}

class AddressView extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);

    const { organization, personProfile, address } = this.props;
    this.state = {
      organization: organization || EmptyOrganization,
      personProfile: personProfile || EmptyPersonProfile,
      address: address || EmptyAddress,
    };
    this.handleEditClick = this.handleEditClick.bind(this);
  }

  handleEditClick(event) {
    event.preventDefault();
    console.log('Edit clicked!');
    const { organization, personProfile, address } = this.state;
    this.props.navigate(`/addresses/${address.id}`, {
      state: { organization, personProfile, address },
    });
  }

  render() {
    const { t } = this.props;
    const { appState, language } = this.context;
    const { organization, personProfile, address } = this.state;
    if (!address) {
      return null;
    }

    const street = getText(address.street, address.streetAm, language);
    const city = getText(address.city, address.cityAm, language);
    const province = getText(
      address.province,
      address.provinceAm,
      language
    ).toUpperCase();
    const country = getText(
      address.country,
      address.countryAm,
      language
    ).toUpperCase();
    const addressLine1Attrs = [
      address.buildingNumber,
      street,
      address.apartmentNumber,
    ];
    const addressLine2Attrs = [city, province, address.postalCode];

    return (
      <Container className='address-view-container'>
        {/* Edit icon at the upper right corner */}
        {canEditContent() && (
          <div className='edit-pencil-container'>
            <a href='' onClick={this.handleEditClick}>
              <FontAwesomeIcon
                icon={faPencilSquare}
                className='edit-pencil-small'
              />
            </a>
          </div>
        )}
        <p>{canEditContent() && renderWithDelimiter(addressLine1Attrs, '')}</p>
        <p>
          {renderWithDelimiter(
            addressLine2Attrs,
            <FontAwesomeIcon icon={faStar} />
          )}
        </p>
        <p>{country}</p>
      </Container>
    );
  }
}
const HOCAddressView = withRouter(withTranslation()(AddressView));
export default HOCAddressView;
