import React, { Component } from 'react';
import { Button, ButtonGroup, Container, Table } from 'reactstrap';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  getAllPublishers,
  searchPublishers,
  deletePublisher,
} from './OpenGradaranApi.js';
import { PageFooter, BackToCatalog } from './utils/CustomViewUtil.js';
import { EmptyPublisher, ResourceName, canEditContent } from './Constant.js';
import { AppContext } from './AppContext.js';
import { linkStyleNoUnderline } from './InlineStyles.js';
import { withTranslation } from 'react-i18next';

/* This is a higher order component that
 *  inject a special prop   to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  }
  return ComponentWithRouter;
}

class PublisherListPaginated extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      publishers: [],
      currentPage: 0,
      totalPageCount: 0,
      totalElementCount: 0,
      pageOffset: 0,
      pageSize: 0,
    };
    this.remove = this.remove.bind(this);
    this.paginate = this.paginate.bind(this);
  }

  async componentDidMount() {
    const { appState } = this.context;
    const selectedPage =
      appState.selectedResource === ResourceName.PUBLISHER
        ? appState.selectedPage
        : 0;
    const queryParams = new URLSearchParams(this.props.location.search);
    const keyword = queryParams.get('keyword');
    if (keyword) {
      this.setState(
        {
          keyword,
        },
        () => {
          // This code will be executed after the state has been updated
          this.setState({ isLoading: true });
          this.paginate({ selected: selectedPage });
        }
      );
    } else {
      this.setState({ isLoading: true });
      this.paginate({ selected: selectedPage });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      const queryParams = new URLSearchParams(this.props.location.search);
      const keyword = queryParams.get('keyword');
      this.setState(
        {
          keyword,
        },
        () => {
          // This code will be executed after the state has been updated
          this.setState({ isLoading: true });
          this.paginate({ selected: 0 });
        }
      );
    }
  }

  async remove(id) {
    // Display a confirmation dialog before proceeding with the delete action
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this entry?'
    );

    if (!isConfirmed) {
      // If the user cancels, exit the function
      return;
    }
    this.setState({ isLoading: true });
    await deletePublisher(id).then((publisherResponse) => {
      if (publisherResponse.message == null) {
        let updatedPublishers = [...this.state.publishers].filter(
          (i) => i.id !== id
        );
        this.setState({ publishers: updatedPublishers, isLoading: false });
      } else {
        this.setState({ isLoading: false });
        //report error
      }
    });
  }

  async paginate({ selected }) {
    const { updateAppSelectedPage } = this.context;
    updateAppSelectedPage(ResourceName.PUBLISHER, selected);

    const keyword = this.state.keyword;
    var publisherPageResponse;
    this.setState({ currentPage: selected });

    if (keyword) {
      publisherPageResponse = await (
        await searchPublishers(keyword, selected)
      ).json();
    } else {
      publisherPageResponse = await (await getAllPublishers(selected)).json();
    }
    if (publisherPageResponse.message == null) {
      const respData = publisherPageResponse.data;
      this.setState((state) => {
        return {
          isLoading: false,
          publishers: respData.page,
          currentPage: selected,
          totalPageCount: respData.totalPages,
          totalElementCount: respData.totalElements,
          pageOffset: respData.offset,
          pageSize: respData.pageSize,
        };
      });
    }
  }

  render() {
    const { t } = this.props;
    const { appState } = this.context;
    const {
      isLoading,
      publishers,
      currentPage,
      totalElementCount,
      totalPageCount,
      pageSize,
      pageOffset,
    } = this.state;

    if (isLoading) {
      return <p>Loading...</p>;
    }

    const publisherList = publishers.map((publisher) => {
      return (
        <tr key={publisher.id}>
          <td>{publisher.id}</td>
          <td style={{ whiteSpace: 'nowrap' }}>
            <Link
              to={`/publishers/page/${publisher.id}`}
              state={{ publisher }}
              style={linkStyleNoUnderline}
            >
              {publisher.name}
            </Link>
          </td>
          {/* <td>{publisher.status}</td> */}
          {canEditContent() && (
            <td>
              <ButtonGroup>
                <Button
                  size='sm'
                  color='primary'
                  tag={Link}
                  to={`/publishers/${publisher.id}`}
                  state={{ publisher }}
                >
                  {t('Edit')}
                </Button>
                <Button
                  size='sm'
                  color='danger'
                  onClick={() => this.remove(publisher.id)}
                >
                  {t('Delete')}
                </Button>
              </ButtonGroup>
            </td>
          )}
        </tr>
      );
    });

    return (
      <div>
        <Container
          fluid
          style={{
            paddingTop: '10px',
            paddingLeft: '40px',
            paddingBottom: '200px',
            overflowX: 'auto',
          }}
        >
          <BackToCatalog />
          {canEditContent() && (
            <div>
              <Button
                color='success'
                tag={Link}
                to='/publishers/new'
                state={{ publisher: { ...EmptyPublisher } }}
              >
                {t('Add Publisher')}
              </Button>
            </div>
          )}
          <br />
          <h3>{t('Publishers')}</h3>
          <Table className='mt-4'>
            <thead>
              <tr>
                <th width='10%'>{t('ID')}</th>
                <th width='50%'>{t('Publisher Name')}</th>
                {/* <th width='20%'>{t('Status')}</th> */}
                {canEditContent() && <th width='20%'>{t('Actions')}</th>}
              </tr>
            </thead>
            <tbody>{publisherList}</tbody>
          </Table>
          <PageFooter
            totalPageCount={totalPageCount}
            currentPage={currentPage}
            paginate={this.paginate}
          />
        </Container>
      </div>
    );
  }
}
const HOCPublisherListPaginated = withRouter(
  withTranslation()(PublisherListPaginated)
);
export default HOCPublisherListPaginated;
