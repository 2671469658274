import React, { Component } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import {
  Button,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Table,
  Row,
  Col,
} from 'reactstrap';
import { Language, LanguageOptions, EmptySeries } from './Constant.js';
import { inputDisableStyle } from './InlineStyles.js';
import {
  postWork,
  postEdition,
  postSeries,
  postAuthor,
  isValidApiResponse,
  postPublisher,
} from './OpenGradaranApi.js';
import {
  DeleteButton,
  CloseButton,
  SaveButton,
} from './utils/CustomViewUtil.js';
import { withTranslation } from 'react-i18next';

/* This is a higher order component that
 *  inject a special prop to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouter;
}

class WorkIsbnForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      work: this.props.work,
      authors: this.props.work.authors,
      edition: this.props.edition,
    };
    this.handleAuthorChange = this.handleAuthorChange.bind(this);
    this.handleWorkChange = this.handleWorkChange.bind(this);
    this.handleSeriesChange = this.handleSeriesChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.handleDelete = this.handleDelete.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.work !== prevProps.work ||
      this.props.edition !== prevProps.edition
    ) {
      this.setState({
        work: this.props.work,
        authors: this.props.work.authors,
        edition: this.props.edition,
      });
    }
  }

  sendDataToEditionForm = (edition, works) => {
    this.props.onData(edition, works);
  };

  handleKeyDown = (event) => {
    if (event.key == 'Enter') {
      event.preventDefault(); // Prevent the default behavior of the "Enter" key
    }
  };

  handleWorkChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let work = this.state.work;
    work[name] = value;

    this.setState((state) => {
      return { work };
    });
  }

  handleSeriesChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let work = this.state.work;
    if (work.series == null) {
      work.series = EmptySeries;
    }
    work.series.title = value;

    this.setState((state) => {
      return { work };
    });
  }

  handleAuthorChange(i, event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let work = { ...this.state.work };
    let authors = this.state.authors;
    authors[i] = { ...authors[i], [name]: value };

    this.setState((state) => {
      return { authors };
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    var work = this.state.work;
    const authors = this.state.authors;
    const edition = this.state.edition;

    //post authors
    var postedAuthors = [];
    for (const author of authors) {
      var authorResponse = await (await postAuthor(author)).json();

      if (authorResponse.message == null) {
        postedAuthors.push(authorResponse);
      } else {
        //report error
      }
    }
    work.authors = postedAuthors;

    if (edition.publisher?.name?.trim().length > 0) {
      var publisherResponse = await (
        await postPublisher(edition.publisher)
      ).json();
      if (isValidApiResponse(publisherResponse)) {
        edition.publisher = publisherResponse;
      } else {
        console.error(
          'Error posting publisher ',
          edition.publisher.name,
          publisherResponse?.message
        );
      }
    } else {
      edition.publisher = null;
    }

    // post edition
    var postedEditions = [];
    edition.works = [];
    var editionResponse = await (await postEdition(edition)).json();
    if (editionResponse.message == null) {
      postedEditions.push(editionResponse);
    } else {
      //report error
    }
    work.editions = postedEditions;

    //post series
    if (work.series?.title?.trim().length > 0) {
      var seriesResponse = await (await postSeries(work.series)).json();
      if (seriesResponse.message == null) {
        work.series = seriesResponse;
      }
    } else {
      work.series = null;
    }

    // post work
    var workResponse = await (await postWork(work)).json();

    if (workResponse.message == null) {
      this.sendDataToEditionForm(workResponse.editions[0], [workResponse]);
      // this.setState((state) => {
      //   return {
      //     work: workResponse,
      //     authors: work.authors,
      //     //edition: editionResponse,
      //   };
      //});
      //this.props.navigate('/works/' + workResponse.id);
    } else {
      //report error
    }
  }

  handleDelete(event) {
    console.log('Delete clicked!');
    event.preventDefault();

    // Display a confirmation dialog before proceeding with the delete action
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this work?'
    );

    if (!isConfirmed) {
      // If the user cancels, exit the function
      return;
    }

    // todo: add delete work API call
  }

  handleClose(event) {
    console.log('Close clicked!');
    event.preventDefault();
    this.props.navigate(-1);
  }

  render() {
    const { t } = this.props;
    let work = this.state.work;
    let authors = this.state.authors;

    const authorGroupList = authors.map((author, i) => {
      return (
        <tr key={i}>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor='id'>Author ID</Label>
                <Input
                  type='text'
                  name='id'
                  id='id'
                  value={author.id}
                  onChange={(author) => this.handleAuthorChange(i, author)}
                  autoComplete='name'
                  readOnly
                  style={inputDisableStyle}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor='openLibraryId'>Author Open Library ID</Label>
                <Input
                  type='text'
                  name='openLibraryId'
                  id='openLibraryId'
                  value={author.openLibraryId}
                  onChange={(author) => this.handleAuthorChange(i, author)}
                  onKeyDown={this.handleKeyDown}
                  autoComplete='name'
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor='Name'>Author Name</Label>
                <Input
                  type='text'
                  name='name'
                  id='name'
                  value={author.name}
                  onChange={(author) => this.handleAuthorChange(i, author)}
                  onKeyDown={this.handleKeyDown}
                  autoComplete='name'
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor='personalName'>Author Personal Name</Label>
                <Input
                  type='text'
                  name='personalName'
                  id='personalName'
                  value={author.personalName}
                  onChange={(author) => this.handleAuthorChange(i, author)}
                  onKeyDown={this.handleKeyDown}
                  autoComplete='name'
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor='firstName'>Author First Name</Label>
                <Input
                  type='text'
                  name='firstName'
                  id='firstName'
                  value={author.firstName}
                  onChange={(author) => this.handleAuthorChange(i, author)}
                  onKeyDown={this.handleKeyDown}
                  autoComplete='name'
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor='middleName'>Author Middle Name</Label>
                <Input
                  type='text'
                  name='middleName'
                  id='middleName'
                  value={author.middleName}
                  onChange={(author) => this.handleAuthorChange(i, author)}
                  onKeyDown={this.handleKeyDown}
                  autoComplete='name'
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor='lastName'>Author Last Name</Label>
                <Input
                  type='text'
                  name='lastName'
                  id='lastName'
                  value={author.lastName}
                  onChange={(author) => this.handleAuthorChange(i, author)}
                  onKeyDown={this.handleKeyDown}
                  autoComplete='name'
                />
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <Label htmlFor='bio'>Author Bio</Label>
            <Input
              type='textarea'
              rows={5}
              name='bio'
              id='bio'
              value={author.bio}
              onChange={(author) => this.handleAuthorChange(i, author)}
              onKeyDown={this.handleKeyDown}
              autoComplete='name'
            />
          </FormGroup>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor='birthDate'>Author Birth Date</Label>
                <Input
                  type='text'
                  name='birthDate'
                  id='birthDate'
                  value={author.birthDate}
                  onChange={(author) => this.handleAuthorChange(i, author)}
                  onKeyDown={this.handleKeyDown}
                  autoComplete='name'
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor='deathDate'>Author Death Date</Label>
                <Input
                  type='text'
                  name='deathDate'
                  id='deathDate'
                  value={author.deathDate}
                  onChange={(author) => this.handleAuthorChange(i, author)}
                  onKeyDown={this.handleKeyDown}
                  autoComplete='name'
                />
              </FormGroup>
            </Col>
          </Row>
        </tr>
      );
    });

    return (
      <div>
        <Container>
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor='id'>Work ID</Label>
                  <Input
                    type='text'
                    name='id'
                    id='id'
                    value={work.id}
                    onChange={this.handleWorkChange}
                    autoComplete='name'
                    readOnly
                    style={inputDisableStyle}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label htmlFor='openLibraryId'>Work Open Library ID</Label>
                  <Input
                    type='text'
                    name='openLibraryId'
                    id='openLibraryId'
                    value={work.openLibraryId}
                    onChange={this.handleWorkChange}
                    onKeyDown={this.handleKeyDown}
                    autoComplete='name'
                  />
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label htmlFor='title'>Work Title</Label>
              <Input
                type='text'
                name='title'
                id='title'
                value={work.title}
                onChange={this.handleWorkChange}
                onKeyDown={this.handleKeyDown}
                autoComplete='name'
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor='subtitle'>Work Subtitle</Label>
              <Input
                type='text'
                name='subtitle'
                id='subtitle'
                value={work.subtitle}
                onChange={this.handleWorkChange}
                onKeyDown={this.handleKeyDown}
                autoComplete='name'
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor='description'>Work Description</Label>
              <Input
                type='textarea'
                rows={5}
                name='description'
                id='description'
                value={work.description}
                onChange={this.handleWorkChange}
                onKeyDown={this.handleKeyDown}
                autoComplete='name'
              />
            </FormGroup>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor='originalLanguage'>Original Language</Label>
                  <select
                    value={work.originalLanguage.toUpperCase()}
                    defaultValue={Language.ENGLISH}
                    onChange={this.handleWorkChange}
                    className='form-control'
                    name='originalLanguage'
                    id='originalLanguage'
                  >
                    {LanguageOptions.map((option) => (
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label htmlFor='series'>Series</Label>
                  <Input
                    type='text'
                    name='series'
                    id='series'
                    value={work?.series?.title}
                    onChange={this.handleSeriesChange}
                    onKeyDown={this.handleKeyDown}
                    autoComplete='name'
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label htmlFor='seriesItemNumber'>Series Item Number</Label>
                  <Input
                    type='text'
                    name='seriesItemNumber'
                    id='seriesItemNumber'
                    value={work.seriesItemNumber}
                    onChange={this.handleWorkChange}
                    onKeyDown={this.handleKeyDown}
                    autoComplete='name'
                  />
                </FormGroup>
              </Col>
            </Row>

            <Table>
              <tbody className='green-white'>{authorGroupList}</tbody>
            </Table>
            {/* <FormGroup>
              <Button color='primary' type='submit'>
                Save
              </Button>{' '}
              <Button color='secondary' tag={Link} to='/authors'>
                Cancel
              </Button>
            </FormGroup> */}
            <FormGroup>
              <SaveButton />
              <CloseButton onClick={this.handleClose} />
              <DeleteButton onClick={this.handleDelete} />
            </FormGroup>
          </Form>
        </Container>
      </div>
    );
  }
}

const HOCWorkIsbnForm = withRouter(withTranslation()(WorkIsbnForm));
export default HOCWorkIsbnForm;
