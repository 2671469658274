import React, { Component } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Container, Form, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import {
  deleteDonationEvent,
  postDonationEvent,
  isValidApiResponse,
} from './OpenGradaranApi';
import { EmptyOrganization, EmptyDonationEvent } from './Constant.js';
import { backgroundStyle, inputDisableStyle } from './InlineStyles.js';
import { LocalDate } from '@js-joda/core'; // Import LocalDate from js-joda
import {
  FormTitle,
  SubFormTitle,
  DeleteButton,
  CloseButton,
  SaveButton,
  StatusFormGroup,
} from './utils/CustomViewUtil.js';
import { withTranslation } from 'react-i18next';

/* This is a higher order component that
 *  inject a special prop to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  }
  return ComponentWithRouter;
}

class DonationEventForm extends Component {
  constructor(props) {
    super(props);

    const { organization, donationEvent } = this.props;
    this.state = {
      organization: organization || EmptyOrganization,
      donationEvent: donationEvent || EmptyDonationEvent,
      isLoading: false, // Loading state for API calls
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.organization !== prevProps.organization ||
      this.props.donationEvent !== prevProps.donationEvent
    ) {
      this.setState({
        organization: this.props.organization,
        donationEvent: this.props.donationEvent,
      });
    }
  }

  async componentDidMount() {
    let donationEvent = this.props.location.state?.donationEvent;
    let organization = this.props.location.state?.organization;
    if (donationEvent && organization) {
      this.setState({
        organization,
        donationEvent,
      });
    }
  }

  handleKeyDown = (event) => {
    if (event.key == 'Enter') {
      event.preventDefault(); // Prevent the default behavior of the "Enter" key
    }
  };

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let donationEvent = { ...this.state.donationEvent };
    if (name == 'startDate' || name == 'endDate') {
      donationEvent[name] = LocalDate.parse(value);
    } else {
      donationEvent[name] = value;
    }
    this.setState({ donationEvent });
  }

  async handleDelete(event) {
    event.preventDefault();

    // Display a confirmation dialog before proceeding with the delete action
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this donation event?'
    );

    if (!isConfirmed) {
      // If the user cancels, exit the function
      return;
    }

    const { donationEvent } = this.state;
    if (donationEvent.id) {
      try {
        const donationEventResponse = await deleteDonationEvent(
          donationEvent.id
        );
        if (isValidApiResponse(donationEventResponse)) {
          this.props.navigate('/donation-events');
        } else {
          // Report invalid API response
          console.error('Invalid API response');
        }
      } catch (error) {
        // Handle network or other errors
        console.error('Error deleting donation event:', error);
      }
    } else {
      this.props.navigate(-1);
    }
  }

  handleClose(event) {
    event.preventDefault();
    this.props.navigate(-1);
  }

  async handleSubmit(event) {
    event.preventDefault();
    const { organization, donationEvent } = this.state;

    donationEvent.organization = organization;
    var donationEventResponse = await (
      await postDonationEvent(donationEvent)
    ).json();

    if (isValidApiResponse(donationEventResponse)) {
      this.props.navigate(-1);
    } else {
      //report error
    }
  }

  render() {
    const { t } = this.props;
    const { organization, donationEvent, isLoading } = this.state;
    if (!donationEvent) {
      return null;
    }
    return (
      <div>
        <div style={backgroundStyle}>
          <Container className='green-white'>
            <Row className='form-container'>
              {
                <FormTitle
                  id={donationEvent.id}
                  name={donationEvent.name}
                  resourceName={'Donation Event'}
                />
              }
              {
                <SubFormTitle
                  name={organization.name}
                  resourceName={'Book Provider'}
                />
              }
              <Form onSubmit={this.handleSubmit}>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='id'>ID</Label>
                      <Input
                        type='text'
                        name='id'
                        id='id'
                        value={donationEvent.id}
                        onChange={this.handleChange}
                        readOnly
                        style={inputDisableStyle}
                      />
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <Label htmlFor='name'>{t('Name')}</Label>
                      <Input
                        type='text'
                        name='name'
                        id='name'
                        value={donationEvent.name}
                        required
                        placeholder='enter event name'
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor='nameAm'>
                        {t('Name')} / {t('in Armenian')}{' '}
                      </Label>
                      <Input
                        type='text'
                        name='nameAm'
                        id='nameAm'
                        value={donationEvent.nameAm}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='startDate'>{t('Start Date')}</Label>
                      <Input
                        type='date'
                        name='startDate'
                        id='startDate'
                        value={
                          donationEvent.startDate
                            ? donationEvent.startDate.toString()
                            : ''
                        }
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='endDate'>{t('End Date')}</Label>
                      <Input
                        type='date'
                        name='endDate'
                        id='endDate'
                        value={
                          donationEvent.endDate
                            ? donationEvent.endDate.toString()
                            : ''
                        }
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='description'>{t('Description')}</Label>
                      <Input
                        type='textarea'
                        rows={3}
                        name='description'
                        id='description'
                        value={donationEvent.description}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor='descriptionAm'>
                        {t('Description')} / {t('in Armenian')}
                      </Label>
                      <Input
                        type='textarea'
                        rows={3}
                        name='descriptionAm'
                        id='descriptionAm'
                        value={donationEvent.descriptionAm}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <StatusFormGroup
                  status={donationEvent.status}
                  handleChange={this.handleChange}
                />
                <FormGroup>
                  <SaveButton />
                  <CloseButton onClick={this.handleClose} />
                  <DeleteButton onClick={this.handleDelete} />
                </FormGroup>
              </Form>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
const HOCDonationEventForm = withRouter(withTranslation()(DonationEventForm));
export default HOCDonationEventForm;
