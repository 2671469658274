import React, { Component } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Container } from 'reactstrap';
import {
  getArmenianLibraryProject,
  isValidApiResponse,
} from './OpenGradaranApi.js';
import { EmptyOrganization } from './Constant.js';
import { backgroundStyle } from './InlineStyles.js';
import AddressView from './AddressView.js';
import ContactView from './ContactView.js';
import { ViewTitle, BackButton } from './utils/CustomViewUtil.js';
import { withTranslation } from 'react-i18next';

/* This is a higher order component that
 *  inject a special prop to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouter;
}

class OrganizationPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      organization: { ...EmptyOrganization },
    };
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  async componentDidMount() {
    const id = this.props.params.id;
    const alpResponse = await (await getArmenianLibraryProject()).json();
    if (isValidApiResponse(alpResponse)) {
      const page = alpResponse.data.page;
      if (page?.length) {
        const organization = page[0];

        this.setState({
          organization,
        });
      }
    } else {
      // handle errors
      console.error('Error fetching data:', alpResponse.message);
    }
  }

  handleEditClick(event) {
    event.preventDefault();
    console.log('Edit clicked!');
    const { organization } = this.state;
    this.props.navigate(`/organizations/${organization.id}`, {
      state: { organization },
    });
  }

  handleGoBack = () => {
    this.props.navigate(-1);
  };

  render() {
    const { organization } = this.state;
    const address = organization.address;
    const contactGroupList = organization.contacts.map((contact, i) => {
      return (
        <div>
          <p>
            <ContactView contact={contact} organization={organization} />
          </p>
        </div>
      );
    });
    const addressView = address ? (
      <AddressView address={organization.address} organization={organization} />
    ) : null;

    let alpParticipantsLink = (
      <Link
        to={`/organizations/alp-participants`}
        className='custom-link-green'
      >
        Participating Schools and Libraries
      </Link>
    );

    let donationEventsLink = (
      <Link
        to={`/organizations/${organization.id}/donation-events`}
        state={{ organization }}
        className='custom-link-green'
      >
        Donation Events
      </Link>
    );

    let donationsLink = (
      <Link
        to={`/organizations/${organization.id}/donations`}
        state={{ organization }}
        className='custom-link-green'
      >
        Donations
      </Link>
    );

    let shippingsLink = (
      <Link
        to={`/organizations/${organization.id}/shipping-boxes`}
        state={{ organization }}
        className='custom-link-green'
      >
        Shippings
      </Link>
    );

    // const organizationType = getLabelByOptionValue(
    //   OrganizationTypeOptions,
    //   organization.type
    // );

    return (
      <div>
        <div style={backgroundStyle}>
          <Container className='light-blue-white'>
            <div style={{ position: 'relative', paddingLeft: '40px' }}>
              <ViewTitle
                name={organization.name}
                resourceName={'Organization'}
              />

              <p>
                {' '}
                Our goal is to provide extracurriculum reading material to
                stimulate English readers in regional schools of the Republic of
                Armenia.
              </p>
              <p>{organization.description}</p>

              {addressView}

              {contactGroupList}
              <p>{alpParticipantsLink}</p>

              <p>{donationsLink}</p>
              <p>{donationEventsLink}</p>
              <p>{shippingsLink}</p>

              <p>
                <BackButton onClick={this.handleGoBack} />
              </p>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
const HOCOrganizationPage = withRouter(withTranslation()(OrganizationPage));
export default HOCOrganizationPage;
