import React, { Component } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Table,
  Row,
  Col,
} from 'reactstrap';
import {
  Language,
  LanguageOptions,
  EmptyWork,
  EmptyEdition,
  EmptyAuthor,
  EmptySeries,
} from './Constant.js';
import { backgroundStyle, inputDisableStyle } from './InlineStyles.js';
import {
  getWork,
  postWork,
  postSeries,
  addWorkToAuthor,
  addWorkToEdition,
  isValidApiResponse,
  deleteWork,
} from './OpenGradaranApi.js';
import {
  FormTitle,
  DeleteButton,
  CloseButton,
  SaveButton,
  StatusFormGroup,
} from './utils/CustomViewUtil.js';
import { withTranslation } from 'react-i18next';

/* This is a higher order component that
 *  inject a special prop to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  }
  return ComponentWithRouter;
}

class WorkForm extends React.Component {
  state = {
    work: { ...EmptyWork },
    edition: { ...EmptyEdition },
    author: { ...EmptyAuthor },
  };

  constructor(props) {
    super(props);
    this.state = {
      work: { ...EmptyWork },
      edition: { ...EmptyEdition },
      author: { ...EmptyAuthor },
    };
    this.handleAuthorChange = this.handleAuthorChange.bind(this);
    this.handleWorkChange = this.handleWorkChange.bind(this);
    this.handleSeriesChange = this.handleSeriesChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.handleDelete = this.handleDelete.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    var work = this.props.location.state?.work;
    var edition = this.props.location.state?.edition;
    var author = this.props.location.state?.author;
    if (!work?.id && this.props.params.id !== 'new') {
      const id = this.props.params.id;
      work = await (await getWork(id)).json();
    }

    this.setState({ work, edition, author });
  }

  handleKeyDown = (event) => {
    if (event.key == 'Enter') {
      event.preventDefault(); // Prevent the default behavior of the "Enter" key
    }
  };

  handleWorkChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let work = this.state.work;
    work[name] = value;

    this.setState((state) => {
      return { work: work };
    });
  }
  handleSeriesChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let work = this.state.work;
    if (work.series == null) {
      work.series = EmptySeries;
    }
    work.series.title = value;

    this.setState((state) => {
      return { work };
    });
  }

  handleAuthorChange(i, event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let work = { ...this.state.work };
    let authors = work.authors;
    authors[i] = { ...authors[i], [name]: value };

    this.setState((state) => {
      return { authors };
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    const { work, edition, author } = this.state;
    //post series
    if (work.series?.title?.trim().length > 0) {
      var seriesResponse = await (await postSeries(work.series)).json();
      if (seriesResponse.message == null) {
        work.series = seriesResponse;
      }
    } else {
      work.series = null;
    }

    // post work
    var workResponse = null;
    if (edition?.id) {
      workResponse = await (await addWorkToEdition(edition.id, work)).json();
    } else if (author?.id) {
      workResponse = await (await addWorkToAuthor(author.id, work)).json();
    } else {
      workResponse = await (await postWork(work)).json();
    }

    if (isValidApiResponse(workResponse)) {
      this.props.navigate('/works');
      // this.setState((state) => {
      //   return { work: workResponse };
      // });
    } else {
      //report error
    }
  }

  async handleDelete(event) {
    event.preventDefault();

    // Display a confirmation dialog before proceeding with the delete action
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this work?'
    );

    if (!isConfirmed) {
      // If the user cancels, exit the function
      return;
    }

    const { work } = this.state;
    if (work.id) {
      try {
        const workResponse = await deleteWork(work.id);
        if (isValidApiResponse(workResponse)) {
          this.props.navigate('/works');
        } else {
          // Report invalid API response
          console.error('Invalid API response');
        }
      } catch (error) {
        // Handle network or other errors
        console.error('Error deleting work:', error);
      }
    } else {
      this.props.navigate(-1);
    }
  }

  handleClose(event) {
    event.preventDefault();
    this.props.navigate(-1);
  }

  render() {
    const { t } = this.props;
    const { edition, work, author } = this.state;

    let authors = work.authors;

    const authorGroupList = authors.map((author, i) => {
      return (
        <tr key={i}>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor='id'>{t('Author ID')}</Label>
                <Input
                  type='text'
                  name='id'
                  id='id'
                  value={author.id}
                  onChange={(author) => this.handleAuthorChange(i, author)}
                  autoComplete='name'
                  readOnlystyle={inputDisableStyle}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor='openLibraryId'>Open Library ID</Label>
                <Input
                  type='text'
                  name='openLibraryId'
                  id='openLibraryId'
                  value={author.openLibraryId}
                  onChange={(author) => this.handleAuthorChange(i, author)}
                  onKeyDown={this.handleKeyDown}
                  autoComplete='name'
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor='Name'>{t('Author Name')}</Label>
                <Input
                  type='text'
                  name='name'
                  id='name'
                  value={author.name}
                  onChange={(author) => this.handleAuthorChange(i, author)}
                  onKeyDown={this.handleKeyDown}
                  autoComplete='name'
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor='personalName'>{t('Personal Name')}</Label>
                <Input
                  type='text'
                  name='personalName'
                  id='personalName'
                  value={author.personalName}
                  onChange={(author) => this.handleAuthorChange(i, author)}
                  onKeyDown={this.handleKeyDown}
                  autoComplete='name'
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor='firstName'>{t('First Name')}</Label>
                <Input
                  type='text'
                  name='firstName'
                  id='firstName'
                  value={author.firstName}
                  onChange={(author) => this.handleAuthorChange(i, author)}
                  onKeyDown={this.handleKeyDown}
                  autoComplete='name'
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor='middleName'>{t('Middle Name')}</Label>
                <Input
                  type='text'
                  name='middleName'
                  id='middleName'
                  value={author.middleName}
                  onChange={(author) => this.handleAuthorChange(i, author)}
                  onKeyDown={this.handleKeyDown}
                  autoComplete='name'
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor='lastName'>{t('Last Name')}</Label>
                <Input
                  type='text'
                  name='lastName'
                  id='lastName'
                  value={author.lastName}
                  onChange={(author) => this.handleAuthorChange(i, author)}
                  onKeyDown={this.handleKeyDown}
                  autoComplete='name'
                />
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <Label htmlFor='bio'>{t('Bio')}</Label>
            <Input
              type='textarea'
              rows={5}
              name='bio'
              id='bio'
              value={author.bio}
              onChange={(author) => this.handleAuthorChange(i, author)}
              onKeyDown={this.handleKeyDown}
              autoComplete='name'
            />
          </FormGroup>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor='birthDate'>{t('Birth Date')}</Label>
                <Input
                  type='text'
                  name='birthDate'
                  id='birthDate'
                  value={author.birthDate}
                  onChange={(author) => this.handleAuthorChange(i, author)}
                  onKeyDown={this.handleKeyDown}
                  autoComplete='name'
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor='deathDate'>{t('Death Date')}</Label>
                <Input
                  type='text'
                  name='deathDate'
                  id='deathDate'
                  value={author.deathDate}
                  onChange={(author) => this.handleAuthorChange(i, author)}
                  onKeyDown={this.handleKeyDown}
                  autoComplete='name'
                />
              </FormGroup>
            </Col>
          </Row>
        </tr>
      );
    });
    return (
      <div>
        <div style={backgroundStyle}>
          <Container className='green-white'>
            <div className='form-container'>
              {author?.id && (
                <FormTitle
                  id={author?.id}
                  name={author?.name}
                  resourceName={'Author'}
                />
              )}
              {edition?.id && (
                <FormTitle
                  id={edition?.id}
                  name={edition?.title}
                  resourceName={'Edition'}
                />
              )}

              {
                <FormTitle
                  id={work.id}
                  name={work.title}
                  resourceName={'Work'}
                />
              }
              <Form onSubmit={this.handleSubmit}>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='id'>{t('Work ID')}</Label>
                      <Input
                        type='text'
                        name='id'
                        id='id'
                        value={work.id}
                        onChange={this.handleWorkChange}
                        autoComplete='name'
                        readOnly
                        style={inputDisableStyle}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='openLibraryId'>Open Library ID</Label>
                      <Input
                        type='text'
                        name='openLibraryId'
                        id='openLibraryId'
                        value={work.openLibraryId}
                        onChange={this.handleWorkChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                        placeholder='/works/XXXXXXXXXX'
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='worldCat'>WorldCat</Label>
                      <Input
                        type='text'
                        name='worldCat'
                        id='worldCat'
                        value={work.worldCat}
                        onChange={this.handleWorkChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Label htmlFor='title'>{t('Title')}</Label>
                  <Input
                    type='text'
                    name='title'
                    id='title'
                    value={work.title}
                    required
                    placeholder='enter work title'
                    onChange={this.handleWorkChange}
                    onKeyDown={this.handleKeyDown}
                    autoComplete='name'
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor='subtitle'>{t('Subtitle')}</Label>
                  <Input
                    type='text'
                    name='subtitle'
                    id='subtitle'
                    value={work.subtitle}
                    onChange={this.handleWorkChange}
                    onKeyDown={this.handleKeyDown}
                    autoComplete='name'
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor='description'>{t('Description')}</Label>
                  <Input
                    type='textarea'
                    rows={5}
                    name='description'
                    id='description'
                    value={work.description}
                    onChange={this.handleWorkChange}
                    onKeyDown={this.handleKeyDown}
                    autoComplete='name'
                  />
                </FormGroup>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='originalLanguage'>
                        {t('Original Language')}
                      </Label>
                      <select
                        value={work.originalLanguage}
                        defaultValue={Language.ENGLISH}
                        onChange={this.handleWorkChange}
                        className='form-control'
                        name='originalLanguage'
                        id='originalLanguage'
                      >
                        {LanguageOptions.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='series'>{t('Series')}</Label>
                      <Input
                        type='text'
                        name='series'
                        id='series'
                        value={work.series?.title || ''}
                        onChange={this.handleSeriesChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='seriesItemNumber'>
                        {t('Series Item Number')}
                      </Label>
                      <Input
                        type='text'
                        name='seriesItemNumber'
                        id='seriesItemNumber'
                        value={work.seriesItemNumber}
                        onChange={this.handleWorkChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='volumeNumber'>{t('Volume Number')}</Label>
                      <Input
                        type='text'
                        name='volumeNumber'
                        id='volumeNumber'
                        value={work.volumeNumber}
                        onChange={this.handleWorkChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Table>
                  <tbody className='green-white'>{authorGroupList}</tbody>
                </Table>
                <StatusFormGroup
                  status={work.status}
                  handleChange={this.handleWorkChange}
                />
                <FormGroup>
                  <SaveButton />
                  <CloseButton onClick={this.handleClose} />
                  <DeleteButton onClick={this.handleDelete} />
                </FormGroup>
              </Form>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

const HOCWorkForm = withRouter(withTranslation()(WorkForm));
export default HOCWorkForm;
