import React, { Component } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';
import { getAuthor } from './OpenGradaranApi.js';
import { EmptyAuthor, canEditContent } from './Constant.js';
import { backgroundStyle } from './InlineStyles.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { ViewTitle, BackButton, DateRange } from './utils/CustomViewUtil.js';
import { withTranslation } from 'react-i18next';
import { AppContext } from './AppContext.js';
import openlibrary from './images/openlibrary-logo-tighter.svg';

/* This is a higher order component that
 *  inject a special prop to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  }
  return ComponentWithRouter;
}

class AuthorPage extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);

    this.state = {
      author: { ...EmptyAuthor },
    };
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  async componentDidMount() {
    const { appState } = this.context;
    const id = this.props.params.id;
    var author = this.props.location.state?.author;
    if (!author && id) {
      const authorResp = await (await getAuthor(id)).json();
      if (authorResp.message == null) {
        author = authorResp;
      } else {
        // handle error
      }
    }
    this.setState({ author: author || EmptyAuthor });
  }

  handleEditClick(event) {
    event.preventDefault();
    console.log('Icon clicked!');
    const { author } = this.state;
    this.props.navigate('/authors/' + author.id);
  }

  handleGoBack = () => {
    this.props.navigate(-1); // Use history.goBack() to navigate back
  };

  render() {
    const { t } = this.props;
    const { appState } = this.context;
    const { author } = this.state;

    return (
      <div>
        <div style={backgroundStyle}>
          <Container
            className='light-blue-white'
            style={{ width: '60%', maxWidth: '720px' }}
          >
            <div
              style={{
                position: 'relative',
                padding: '30px',
              }}
            >
              <ViewTitle name={author.name} resourceName={'Author'} />
              {
                <DateRange
                  startDate={author.birthDate}
                  endDate={author.deathDate}
                />
              }
              <p>{author.bio}</p>

              {author.openLibraryId && (
                <p>
                  <Link
                    to={`https://openlibrary.org/${author.openLibraryId}`}
                    className='custom-green'
                  >
                    <img
                      src={openlibrary}
                      alt='Open Library'
                      className='external-logo'
                    />
                  </Link>
                </p>
              )}

              <p>
                <Link
                  to={`/authors/${author.id}/works`}
                  state={{ author }}
                  className='custom-green'
                >
                  {t('Works')}
                </Link>
              </p>
              <p>
                <BackButton onClick={this.handleGoBack} />
              </p>
              {/* Edit icon at the upper right corner */}
              {canEditContent() && (
                <div className='edit-pencil-container'>
                  <a href='' onClick={this.handleEditClick}>
                    <FontAwesomeIcon
                      icon={faPencilAlt}
                      className='edit-pencil'
                    />
                  </a>
                </div>
              )}
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
const HOCAuthorPage = withRouter(withTranslation()(AuthorPage));
export default HOCAuthorPage;
