import React from 'react';

export function getFullName(personProfile) {
  if (personProfile) {
    return `${personProfile?.firstName} ${personProfile?.lastName}`.trim();
  }
  return '';
}

// Function to truncate the title and add ellipsis if it's too long
export const getEllipsizedText = (text, maxLength) => {
  return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
};

export const renderWithDelimiter = (texts, delimiter) => {
  return texts
    .filter((text) => text) // Filter out null, undefined, or empty strings
    .map((text, index, array) => (
      <React.Fragment key={index}>
        {text} {index < array.length - 1 && delimiter}{' '}
        {/* Add the delimiter between items */}
      </React.Fragment>
    ));
};
