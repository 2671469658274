import React, { Component } from 'react';
import { Button, ButtonGroup, Container, Table } from 'reactstrap';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  getAllAuthors,
  searchAuthorsByKeyword,
  deleteAuthor,
  isValidApiResponse,
} from './OpenGradaranApi';
import { PageFooter, BackToCatalog } from './utils/CustomViewUtil.js';
import { EmptyAuthor, ResourceName, canEditContent } from './Constant.js';
import { AppContext } from './AppContext.js';
import { linkStyleNoUnderline } from './InlineStyles.js';
import { withTranslation } from 'react-i18next';

/* This is a higher order component that
 *  inject a special prop   to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  }
  return ComponentWithRouter;
}

class AuthorListPaginated extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      authors: [],
      currentPage: 0,
      totalPageCount: 0,
      totalElementCount: 0,
      pageOffset: 0,
      pageSize: 0,
      sortField: 'name', // Field to sort by
      sortDirection: 'asc', // Sorting direction ('asc' or 'desc')
    };
    this.remove = this.remove.bind(this);
    this.paginate = this.paginate.bind(this);
    this.sortByName = this.sortByName.bind(this);
  }

  async componentDidMount() {
    const { appState } = this.context;
    const selectedPage =
      appState.selectedResource === ResourceName.AUTHOR
        ? appState.selectedPage
        : 0;
    const queryParams = new URLSearchParams(this.props.location.search);
    const keyword = queryParams.get('keyword');
    if (keyword) {
      this.setState(
        {
          keyword,
        },
        () => {
          // This code will be executed after the state has been updated
          this.setState({ isLoading: true });
          this.paginate({ selected: selectedPage });
        }
      );
    } else {
      this.setState({ isLoading: true });
      this.paginate({ selected: selectedPage });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      const queryParams = new URLSearchParams(this.props.location.search);
      const keyword = queryParams.get('keyword');
      this.setState(
        {
          keyword,
        },
        () => {
          // This code will be executed after the state has been updated
          this.setState({ isLoading: true });
          this.paginate({ selected: 0 });
        }
      );
    }
  }

  async remove(id) {
    // Display a confirmation dialog before proceeding with the delete action
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this entry?'
    );

    if (!isConfirmed) {
      // If the user cancels, exit the function
      return;
    }

    this.setState({ isLoading: true });
    await deleteAuthor(id).then((authorResponse) => {
      if (isValidApiResponse(authorResponse)) {
        let updatedAuthors = [...this.state.authors].filter((i) => i.id !== id);
        this.setState({ authors: updatedAuthors, isLoading: false });
      } else {
        this.setState({ isLoading: false });
        //report error
      }
    });
  }

  async sortByName() {
    const { sortDirection } = this.state;

    // Toggle sort direction
    const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';

    this.setState(
      { sortField: 'name', sortDirection: newSortDirection, isLoading: true },
      () => this.paginate({ selected: 0 }) // Reset to the first page when sorting
    );
  }

  async paginate({ selected }) {
    const { updateAppSelectedPage } = this.context;
    updateAppSelectedPage(ResourceName.AUTHOR, selected);

    const keyword = this.state.keyword;
    var authorPageResponse;
    this.setState({ currentPage: selected });
    if (keyword) {
      authorPageResponse = await (
        await searchAuthorsByKeyword(keyword, selected)
      ).json();
    } else {
      authorPageResponse = await (await getAllAuthors(selected)).json();
    }
    if (authorPageResponse.message == null) {
      const respData = authorPageResponse.data;
      this.setState((state) => {
        return {
          isLoading: false,
          authors: respData.page,
          currentPage: selected,
          totalPageCount: respData.totalPages,
          totalElementCount: respData.totalElements,
          pageOffset: respData.offset,
          pageSize: respData.pageSize,
        };
      });
    }
  }

  render() {
    const { t } = this.props;
    const { appState } = this.context;
    const {
      isLoading,
      authors,
      currentPage,
      totalElementCount,
      totalPageCount,
      pageSize,
      pageOffset,
      sortDirection,
    } = this.state;

    if (isLoading) {
      return <p>Loading...</p>;
    }

    const authorList = authors.map((author) => {
      return (
        <tr key={author.id}>
          <td>{author.id}</td>
          <td style={{ whiteSpace: 'nowrap' }}>
            <Link
              to={`/authors/page/${author.id}`}
              state={{ author }}
              style={linkStyleNoUnderline}
            >
              {author.name?.length > 0
                ? author.name
                : author.firstName + ' ' + author.lastName}
            </Link>
          </td>
          {/* <td>{author.firstName}</td>
          <td>{author.lastName}</td> */}
          {canEditContent() && <td>{author.birthDate}</td>}
          {canEditContent() && <td>{author.openLibraryId}</td>}
          {/* <td>{author.status}</td> */}
          {canEditContent() && (
            <td>
              <ButtonGroup>
                <Button
                  size='sm'
                  color='primary'
                  tag={Link}
                  to={`/authors/${author.id}`}
                  state={{ author }}
                >
                  {t('Edit')}
                </Button>
                <Button
                  size='sm'
                  color='danger'
                  onClick={() => this.remove(author.id)}
                >
                  {t('Delete')}
                </Button>
              </ButtonGroup>
            </td>
          )}
        </tr>
      );
    });

    return (
      <div>
        <Container
          fluid
          style={{
            paddingTop: '10px',
            paddingLeft: '40px',
            paddingBottom: '200px',
          }}
        >
          <BackToCatalog />
          {canEditContent() && (
            <div>
              <Button
                color='success'
                tag={Link}
                to='/authors/new'
                state={{ author: { ...EmptyAuthor } }}
              >
                {t('Add Author')}
              </Button>
            </div>
          )}
          <br />
          <h3>{t('Authors')}</h3>
          <Table className='mt-4'>
            <thead>
              <tr>
                <th width='10%'>ID</th>
                <th
                  width='20%'
                  onClick={this.sortByName}
                  style={{ cursor: 'pointer' }}
                >
                  {t('Author Name')}
                  {/* {sortDirection === 'asc' ? ' 🔼 ' : ' 🔽 '} */}
                </th>
                {/* <th width='10%'>{t('First Name')}</th>
                <th width='10%'>{t('Last Name')}</th> */}
                {canEditContent() && <th width='10%'>{t('Birth Date')}</th>}
                {canEditContent() && <th width='20%'>{t('Open Libary ID')}</th>}
                {/* <th width='20%'>{t('Status')}</th> */}
                {canEditContent() && <th width='20%'>{t('Actions')}</th>}
              </tr>
            </thead>
            <tbody>{authorList}</tbody>
          </Table>
          <PageFooter
            totalPageCount={totalPageCount}
            currentPage={currentPage}
            paginate={this.paginate}
          />
        </Container>
      </div>
    );
  }
}
const HOCAuthorListPaginated = withRouter(
  withTranslation()(AuthorListPaginated)
);
export default HOCAuthorListPaginated;
