import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Form, FormGroup, Row, Col, Input, Label } from 'reactstrap';
import WorkIsbnForm from './WorkIsbnForm.js';
import { buildGradarakEdition } from './OpenLibraryApi.js';
import {
  EmptyEdition,
  Language,
  LanguageOptions,
  PhysicalFormat,
  PhysicalFormatOptions,
  GradeLevel,
  GradeLevelOptions,
} from './Constant.js';
import { backgroundStyle, inputDisableStyle } from './InlineStyles.js';
import { withTranslation } from 'react-i18next';
import { getOpenLibraryMediumCoverUrl } from './OpenLibraryConverter.js';

/* This is a higher order component that
 *  inject a special prop   to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouter;
}

class EditionIsbnForm extends Component {
  state = {
    edition: { ...EmptyEdition },
    works: [],
  };

  constructor(props) {
    super(props);
    //const { itemId, otherParam } = route.params;
    this.state = {
      edition: { ...EmptyEdition },
      works: [],
    };
    this.handleEditionChange = this.handleEditionChange.bind(this);
    this.handlePublisherChange = this.handlePublisherChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  async componentDidMount() {
    //const isbn = this.props.params('isbn', '');

    //const isbn = this.props.navigation.state.params.isbn;
    // const openLibraryEdition2 = await (
    //   await fetch('https://openlibrary.org/isbn/9780547928210.json')
    // ).json();
    //https://openlibrary.org/isbn/9780394800769.json

    //const openLibraryEdition = await (await fetch("https://openlibrary.org/isbn/0064400018.json")).json();
    //const openLibraryEdition = await (await fetch("https://openlibrary.org/isbn/9780141374284.json")).json();
    //const openLibraryEdition = await (await fetch("https://openlibrary.org/isbn/9781435238138.json")).json();
    //const openLibraryEdition = await (await fetch("https://openlibrary.org/isbn/9780747595823.json")).json();
    //const openLibraryEdition = await (await fetch("https://openlibrary.org/isbn/0439441749.json")).json();
    //https://openlibrary.org/api/books?bibkeys=ISBN:9780140328721&jscmd=data&format=json // returns covers (small, medium, large), publishers(name)
    //"cover": {
    //"small": "https://covers.openlibrary.org/b/id/10237130-S.jpg",
    //"medium": "https://covers.openlibrary.org/b/id/10237130-M.jpg",
    //"large": "https://covers.openlibrary.org/b/id/10237130-L.jpg"
    //}

    const isbn = this.props.params.isbn;

    const edition = await buildGradarakEdition(isbn);
    if (!edition) {
      edition = EmptyEdition;
    }

    this.setState((state) => {
      return { edition: edition, works: edition.works };
    });
  }

  handleDataFromWorkForm = (edition, works) => {
    this.setState((state) => {
      return { edition, works };
    });
  };

  handleKeyDown = (event) => {
    if (event.key == 'Enter') {
      event.preventDefault(); // Prevent the default behavior of the "Enter" key
    }
  };

  handlePublisherChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let edition = this.state.edition;
    edition.publisher.name = value;

    this.setState((state) => {
      return { edition };
    });
  }

  handleEditionChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let edition = this.state.edition;
    edition[name] = value;

    this.setState((state) => {
      return { edition: edition };
    });
  }

  async handleSubmit(event) {
    // event.preventDefault();
    // const edition = this.state.edition;
    // const works = this.state.works;
    // var editionResponse = await (
    //   await fetch('/api/v1/editions', {
    //     method: 'POST',
    //     headers: {
    //       Accept: 'application/json',
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(edition),
    //   })
    // ).json();
    // if (editionResponse.message == null) {
    //   this.setState((state) => {
    //     return { edition: editionResponse };
    //   });
    // } else {
    //   //report error
    // }
    // //this.props.navigate("/authors");
  }

  render() {
    const { t } = this.props;
    const edition = this.state.edition;
    const works = this.state.works;

    const title = (
      <div class='blue-white-center'>
        <h2>{edition.title}</h2>
      </div>
    );

    let coverUrl = getOpenLibraryMediumCoverUrl(edition.openLibraryCoverId);

    var workList = works.map((work, i) => {
      return (
        <div key={i}>
          <WorkIsbnForm
            work={work}
            edition={edition}
            onData={this.handleDataFromWorkForm}
          />
        </div>
      );
    });

    return (
      <div>
        <div style={backgroundStyle}>
          <Container>
            <div class='blue-white'>
              {title}
              <Form onSubmit={this.handleSubmit}>
                <Row className='mb-3'>
                  <Col>
                    <FormGroup>
                      <img
                        src={coverUrl}
                        alt='edition cover'
                        className='edition-page-image'
                      />
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <Label htmlFor='id'>Edition ID</Label>
                      <Input
                        color='primary'
                        type='text'
                        name='id'
                        id='id'
                        value={edition.id}
                        onChange={this.handleEditionChange}
                        autoComplete='name'
                        readOnly
                        style={inputDisableStyle}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor='openLibraryId'>
                        Edition Open Library ID
                      </Label>
                      <Input
                        type='text'
                        name='openLibraryId'
                        id='openLibraryId'
                        value={edition.openLibraryId}
                        onChange={this.handleWorkChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor='cover'>Open Library Cover ID</Label>
                      <Input
                        type='text'
                        name='cover'
                        id='cover'
                        value={edition.openLibraryCoverId}
                        onChange={this.handleEditionChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor='worldCat'>WorldCat Link</Label>
                      <Input
                        type='text'
                        name='worldCat'
                        id='worldCat'
                        value={edition.worldCat}
                        onChange={this.handleEditionChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <Label htmlFor='isbn13'>ISBN13</Label>
                      <Input
                        type='text'
                        name='isbn13'
                        id='isbn13'
                        value={edition.isbn13}
                        onChange={this.handleEditionChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor='isbn10'>ISBN10</Label>
                      <Input
                        type='text'
                        name='isbn10'
                        id='isbn_10'
                        value={edition.isbn10}
                        onChange={this.handleEditionChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup>
                  <Label htmlFor='title'>Edition Title</Label>
                  <Input
                    color='primary'
                    type='text'
                    name='title'
                    id='title'
                    value={edition.title}
                    required
                    placeholder='enter edition title'
                    onChange={this.handleEditionChange}
                    onKeyDown={this.handleKeyDown}
                    autoComplete='name'
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor='subtitle'>Edition Subtitle</Label>
                  <Input
                    type='text'
                    name='subtitle'
                    id='subtitle'
                    value={edition.subtitle}
                    onChange={this.handleEditionChange}
                    onKeyDown={this.handleKeyDown}
                    autoComplete='name'
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor='full_title'>Edition Full Title</Label>
                  <Input
                    type='text'
                    name='fullTitle'
                    id='fullTitle'
                    value={edition.fullTitle}
                    onChange={this.handleEditionChange}
                    onKeyDown={this.handleKeyDown}
                    autoComplete='name'
                  />
                </FormGroup>
                <Row className='mb-3'>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='language'>Language</Label>
                      <select
                        value={edition.language.toUpperCase()}
                        defaultValue={Language.ENGLISH}
                        onChange={this.handleEditionChange}
                        onKeyDown={this.handleKeyDown}
                        className='form-control'
                        name='language'
                        id='language'
                      >
                        {LanguageOptions.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </select>
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor='pageCount'>Page Count</Label>
                      <Input
                        type='text'
                        name='pageCount'
                        id='pageCount'
                        value={edition.pageCount}
                        onChange={this.handleEditionChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor='physicalFormat'>Physical Format</Label>
                      <select
                        value={edition.physicalFormat}
                        defaultValue={PhysicalFormat.BOOK_PAPERBACK}
                        onChange={this.handleEditionChange}
                        onKeyDown={this.handleKeyDown}
                        className='form-control'
                        name='physicalFormat'
                        id='physicalFormat'
                      >
                        {PhysicalFormatOptions.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <Label htmlFor='publisher'>Publisher</Label>
                      <Input
                        type='text'
                        name='publisher'
                        id='publisher'
                        value={edition.publisher.name}
                        onChange={this.handlePublisherChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor='publishPlace'>Publish Place</Label>
                      <Input
                        type='text'
                        name='publishPlace'
                        id='publishPlace'
                        value={edition.publishPlace}
                        onChange={this.handleEditionChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor='publishDate'>Publish Date</Label>
                      <Input
                        type='text'
                        name='publishDate'
                        id='publishDate'
                        value={edition.publishDate}
                        onChange={this.handleEditionChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row className='mb-3'>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='startGradeLevel'>Start Grade Level</Label>
                      <select
                        value={edition.gradeLevelStart}
                        defaultValue={GradeLevel.UNDEFINED}
                        onChange={this.handleEditionChange}
                        onKeyDown={this.handleKeyDown}
                        className='form-control'
                        name='gradeLevelStart'
                        id='gradeLevelStart'
                      >
                        {GradeLevelOptions.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='endGradeLevel'>End Grade Level</Label>
                      <select
                        value={edition.gradeLevelEnd}
                        defaultValue={GradeLevel.UNDEFINED}
                        onChange={this.handleEditionChange}
                        onKeyDown={this.handleKeyDown}
                        className='form-control'
                        name='gradeLevelEnd'
                        id='gradeLevelEnd'
                      >
                        {GradeLevelOptions.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                </Row>

                {/* <FormGroup>
                <Button color='primary' type='submit'>
                  Save
                </Button>{' '}
                <Button color='secondary' tag={Link} to='/authors'>
                  Cancel
                </Button>
              </FormGroup> */}
              </Form>
            </div>
            <div className='green-white'>{workList}</div>
          </Container>
        </div>
      </div>
    );
  }
}
const HOCEditionIsbnForm = withRouter(withTranslation()(EditionIsbnForm));
export default HOCEditionIsbnForm;
