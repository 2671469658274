import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Form, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import {
  getPublisher,
  postPublisher,
  deletePublisher,
  isValidApiResponse,
} from './OpenGradaranApi.js';
import { EmptyPublisher } from './Constant.js';
import { backgroundStyle, inputDisableStyle } from './InlineStyles.js';
import {
  FormTitle,
  DeleteButton,
  CloseButton,
  SaveButton,
  StatusFormGroup,
} from './utils/CustomViewUtil.js';
import { useTranslation } from 'react-i18next';

const PublisherForm = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [publisher, setPublisher] = useState({ ...EmptyPublisher });

  useEffect(() => {
    const fetchPublisher = async () => {
      if (id !== 'new') {
        const fetchedPublisher = await (await getPublisher(id)).json();
        setPublisher(fetchedPublisher);
      }
    };

    fetchPublisher();
  }, [id]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPublisher((prevPublisher) => ({
      ...prevPublisher,
      [name]: value,
    }));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default behavior of the "Enter" key
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const publisherResponse = await (await postPublisher(publisher)).json();
    if (isValidApiResponse(publisherResponse)) {
      navigate('/publishers');
    } else {
      // report error
    }
  };

  const handleClose = (event) => {
    event.preventDefault();
    navigate(-1);
  };

  const handleDelete = async (event) => {
    event.preventDefault();

    // Display a confirmation dialog before proceeding with the delete action
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this publisher?'
    );

    if (!isConfirmed) {
      // If the user cancels, exit the function
      return;
    }

    if (publisher.id) {
      try {
        const publisherResponse = await deletePublisher(publisher.id);
        if (isValidApiResponse(publisherResponse)) {
          navigate('/publishers');
        } else {
          // Report invalid API response
          console.error('Invalid API response');
        }
      } catch (error) {
        // Handle network or other errors
        console.error('Error deleting publisher:', error);
      }
    } else {
      navigate(-1);
    }
  };

  return (
    <div>
      <div style={backgroundStyle}>
        <Container className='green-white'>
          <div className='form-container'>
            <FormTitle
              id={publisher.id}
              name={publisher.name}
              resourceName={'Publisher'}
            />
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor='id'>ID</Label>
                    <Input
                      type='text'
                      name='id'
                      id='id'
                      value={publisher.id}
                      onChange={handleChange}
                      autoComplete='name'
                      readOnly
                      style={inputDisableStyle}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor='name'>{t('Publisher Name')}</Label>
                    <Input
                      type='text'
                      name='name'
                      id='name'
                      value={publisher.name}
                      required
                      placeholder='enter publisher name'
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      autoComplete='name'
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor='nameAm'>
                      {t('Publisher Name')} / {t('in Armenian')}
                    </Label>
                    <Input
                      type='text'
                      name='nameAm'
                      id='nameAm'
                      value={publisher.nameAm}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      autoComplete='nameAm'
                    />
                  </FormGroup>
                </Col>
              </Row>
              <StatusFormGroup
                status={publisher.status}
                handleChange={handleChange}
              />
              <FormGroup>
                <SaveButton />
                <CloseButton onClick={handleClose} />
                <DeleteButton onClick={handleDelete} />
              </FormGroup>
            </Form>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default PublisherForm;
