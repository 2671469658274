export const Language = {
  UNDEFINED: 'UNDEFINED',
  ARMENIAN: 'ARMENIAN',
  ENGLISH: 'ENGLISH',
  FRENCH: 'FRENCH',
  GEORGIAN: 'GEORGIAN',
  GERMAN: 'GERMAN',
  ITALIAN: 'ITALIAN',
  RUSSIAN: 'RUSSIAN',
  SPANISH: 'SPANISH',
  UKRAINIAN: 'UKRAINIAN',
};

export const PhysicalFormat = {
  UNDEFINED: 'UNDEFINED',
  AUDIOBOOK_CD: 'AUDIOBOOK_CD',
  AUDIOBOOK_DOWNLOADABLE: 'AUDIOBOOK_DOWNLOADABLE',
  AUDIOBOOK_PRELOADED: 'AUDIOBOOK_PRELOADED',
  BOOK_BOARD: 'BOOK_BOARD',
  BOOK_ELECTRONIC: 'BOOK_ELECTRONIC',
  BOOK_LARGE_PRINT: 'BOOK_LARGE_PRINT',
  BOOK_LIBRARY_BINDING: 'BOOK_LIBRARY_BINDING',
  BOOK_HARDCOVER: 'BOOK_HARDCOVER',
  BOOK_PAPERBACK: 'BOOK_PAPERBACK',
  PERIODICAL_MAGAZINE: 'PERIODICAL_MAGAZINE',
};

export const PhysicalFormatOptions = [
  { value: PhysicalFormat.UNDEFINED, label: 'Undefined' },
  { value: PhysicalFormat.AUDIOBOOK_CD, label: 'Audiobook CD' },
  {
    value: PhysicalFormat.AUDIOBOOK_DOWNLOADABLE,
    label: 'Audiobook Downloadable',
  },
  { value: PhysicalFormat.AUDIOBOOK_PRELOADED, label: 'Audiobook Preloaded' },
  { value: PhysicalFormat.BOOK_BOARD, label: 'Board Book' },
  { value: PhysicalFormat.BOOK_ELECTRONIC, label: 'Electronic Book' },
  { value: PhysicalFormat.BOOK_LARGE_PRINT, label: 'Large Print Book' },
  { value: PhysicalFormat.BOOK_LIBRARY_BINDING, label: 'Library Binding Book' },
  { value: PhysicalFormat.BOOK_HARDCOVER, label: 'Hardcover Book' },
  { value: PhysicalFormat.BOOK_PAPERBACK, label: 'Paperback Book' },
  { value: PhysicalFormat.PERIODICAL_MAGAZINE, label: 'Periodical Magazine' },
];

export const LanguageOptions = [
  { value: Language.UNDEFINED, label: 'Undefined' },
  { value: Language.ARMENIAN, label: 'Armenian' },
  { value: Language.ENGLISH, label: 'English' },
  { value: Language.FRENCH, label: 'French' },
  { value: Language.GEORGIAN, label: 'Georgian' },
  { value: Language.GERMAN, label: 'German' },
  { value: Language.ITALIAN, label: 'Italian' },
  { value: Language.RUSSIAN, label: 'Russian' },
  { value: Language.SPANISH, label: 'Spanish' },
  { value: Language.UKRAINIAN, label: 'Ukrainian' },
];

export const GradeLevel = {
  UNDEFINED: 'UNDEFINED',
  GRADE_PRE_K: 'GRADE_PRE_K',
  GRADE_K: 'GRADE_K',
  GRADE_1: 'GRADE_1',
  GRADE_2: 'GRADE_2',
  GRADE_3: 'GRADE_3',
  GRADE_4: 'GRADE_4',
  GRADE_5: 'GRADE_5',
  GRADE_6: 'GRADE_6',
  GRADE_7: 'GRADE_7',
  GRADE_8: 'GRADE_8',
  GRADE_9: 'GRADE_9',
  GRADE_10: 'GRADE_10',
  GRADE_11: 'GRADE_11',
  GRADE_12: 'GRADE_12',
};

export const GradeLevelOptions = [
  { value: GradeLevel.UNDEFINED, label: 'Undefined' },
  { value: GradeLevel.GRADE_PRE_K, label: 'Preschool' },
  { value: GradeLevel.GRADE_K, label: 'Grade K' },
  { value: GradeLevel.GRADE_1, label: 'Grade 1' },
  { value: GradeLevel.GRADE_2, label: 'Grade 2' },
  { value: GradeLevel.GRADE_3, label: 'Grade 3' },
  { value: GradeLevel.GRADE_4, label: 'Grade 4' },
  { value: GradeLevel.GRADE_5, label: 'Grade 5' },
  { value: GradeLevel.GRADE_6, label: 'Grade 6' },
  { value: GradeLevel.GRADE_7, label: 'Grade 7' },
  { value: GradeLevel.GRADE_8, label: 'Grade 8' },
  { value: GradeLevel.GRADE_9, label: 'Grade 9' },
  { value: GradeLevel.GRADE_10, label: 'Grade 10' },
  { value: GradeLevel.GRADE_11, label: 'Grade 11' },
  { value: GradeLevel.GRADE_12, label: 'Grade 12' },
];

export const BookStatus = {
  UNDEFINED: 'UNDEFINED',
  NEW: 'NEW',
  AVAILABLE: 'AVAILABLE',
  CHECKED_OUT: 'CHECKED_OUT',
  ON_HOLD_SHELF: 'ON_HOLD_SHELF',
  LIBRARY_USE_ONLY: 'LIBRARY_USE_ONLY',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  DAMAGED: 'DAMAGED',
  MISSING: 'MISSING',
  LOST: 'LOST',
  LOST_AND_PAID: 'LOST_AND_PAID',
  IN_PROCESSING: 'IN_PROCESSING',
  IN_TRANSIT: 'IN_TRANSIT',
  WITHDRAWN: 'WITHDRAWN',
  ON_ORDER: 'ON_ORDER',
  ORDER_CANCELLED: 'ORDER_CANCELLED',
  CLAIMS_RETURNED: 'CLAIMS_RETURNED',
};

export const BookStatusOptions = [
  { value: BookStatus.UNDEFINED, label: 'Undefined' },
  { value: BookStatus.NEW, label: 'New' },
  { value: BookStatus.AVAILABLE, label: 'Available' },
  { value: BookStatus.CHECKED_OUT, label: 'Checked out' },
  { value: BookStatus.ON_HOLD_SHELF, label: 'On hold shelf' },
  { value: BookStatus.LIBRARY_USE_ONLY, label: 'Library use only' },
  { value: BookStatus.NOT_AVAILABLE, label: 'Not available' },
  { value: BookStatus.DAMAGED, label: 'Damaged' },
  { value: BookStatus.MISSING, label: 'Missing' },
  { value: BookStatus.LOST, label: 'Lost' },
  { value: BookStatus.LOST_AND_PAID, label: 'Lost and paid' },
  { value: BookStatus.IN_PROCESSING, label: 'In processing' },
  { value: BookStatus.IN_TRANSIT, label: 'In transit' },
  { value: BookStatus.WITHDRAWN, label: 'Withdrawn' },
  { value: BookStatus.ON_ORDER, label: 'On order' },
  { value: BookStatus.ORDER_CANCELLED, label: 'Order cancelled' },
  { value: BookStatus.CLAIMS_RETURNED, label: 'Claims returned' },
];

export const DonationStatus = {
  UNDEFINED: 'UNDEFINED',
  NEW: 'NEW',
  ACCEPTED: 'ACCEPTED',
  PROCESSED: 'PROCESSED',
  ASSIGNED: 'ASSIGNED',
  DELIVERED: 'DELIVERED',
  MISSING: 'MISSING',
  LOST: 'LOST',
  DAMAGED: 'DAMAGED',
  REJECTED: 'REJECTED',
};

export const DonationStatusOptions = [
  { value: DonationStatus.UNDEFINED, label: 'Undefined' },
  { value: DonationStatus.NEW, label: 'New' },
  { value: DonationStatus.ACCEPTED, label: 'Accepted' },
  { value: DonationStatus.PROCESSED, label: 'Processed' },
  { value: DonationStatus.ASSIGNED, label: 'Assigned' },
  { value: DonationStatus.DELIVERED, label: 'Delivered' },
  { value: DonationStatus.MISSING, label: 'Missing' },
  { value: DonationStatus.LOST, label: 'Lost' },
  { value: DonationStatus.DAMAGED, label: 'Damaged' },
  { value: DonationStatus.REJECTED, label: 'Rejected' },
];

export const ShippingStatus = {
  UNDEFINED: 'UNDEFINED',
  NEW: 'NEW',
  PROCESSED: 'PROCESSED',
  SHIPPED: 'SHIPPED',
  IN_TRANSIT: 'IN_TRANSIT',
  AT_WAREHOUSE_USA: 'AT_WAREHOUSE_USA',
  AT_WAREHOUSE_ARMENIA: 'AT_WAREHOUSE_ARMENIA',
  DELIVERED: 'DELIVERED',
  MISSING: 'MISSING',
  LOST: 'LOST',
  DAMAGED: 'DAMAGED',
};

export const ShippingStatusOptions = [
  { value: ShippingStatus.UNDEFINED, label: 'Undefined' },
  { value: ShippingStatus.NEW, label: 'New' },
  { value: ShippingStatus.PROCESSED, label: 'Processed' },
  { value: ShippingStatus.SHIPPED, label: 'Shipped' },
  { value: ShippingStatus.IN_TRANSIT, label: 'In transit' },
  { value: ShippingStatus.AT_WAREHOUSE_USA, label: 'At warehouse in USA' },
  {
    value: ShippingStatus.AT_WAREHOUSE_ARMENIA,
    label: 'At warehouse in Armenia',
  },
  { value: ShippingStatus.DELIVERED, label: 'Delivered' },
  { value: ShippingStatus.MISSING, label: 'Missing' },
  { value: ShippingStatus.LOST, label: 'Lost' },
  { value: ShippingStatus.DAMAGED, label: 'Damaged' },
];

export const Status = {
  NEW: 'NEW',
  ACTIVE: 'ACTIVE',
  SUSPENDED: 'SUSPENDED',
  DELETED: 'DELETED',
};

export const StatusOptions = [
  { value: Status.NEW, label: 'New' },
  { value: Status.ACTIVE, label: 'Active' },
  { value: Status.SUSPENDED, label: 'Suspended' },
  { value: Status.DELETED, label: 'Deleted' },
];

export const StarRating = {
  UNDEFINED: 'UNDEFINED',
  ONE_STAR: 'ONE_STAR',
  TWO_STARS: 'TWO_STARS',
  THREE_STARS: 'THREE_STARS',
  FOUR_STARS: 'FOUR_STARS',
  FIVE_STARS: 'FIVE_STARS',
};

export const OrganizationType = {
  UNDEFINED: 'UNDEFINED',
  LIBRARY: 'LIBRARY',
  SCHOOL: 'SCHOOL',
  BOOK_PROVIDER_ORGANIZATION: 'BOOK_PROVIDER_ORGANIZATION',
  GOVERNMENT_ORGANIZATION: 'GOVERNMENT_ORGANIZATION',
  NON_GOVERNMENTAL_ORGANIZATION: 'NON_GOVERNMENTAL_ORGANIZATION',
  NON_PROFIT_ORGANIZATION: 'NON_PROFIT_ORGANIZATION',
  OTHER: 'OTHER',
};

export const OrganizationTypeOptions = [
  { value: OrganizationType.UNDEFINED, label: 'Undefined' },
  { value: OrganizationType.LIBRARY, label: 'Library' },
  { value: OrganizationType.SCHOOL, label: 'School' },
  {
    value: OrganizationType.BOOK_PROVIDER_ORGANIZATION,
    label: 'Book Provider Organization',
  },
  {
    value: OrganizationType.GOVERNMENT_ORGANIZATION,
    label: 'Government Organization',
  },
  {
    value: OrganizationType.NON_GOVERNMENTAL_ORGANIZATION,
    label: 'NGO',
  },
  {
    value: OrganizationType.NON_PROFIT_ORGANIZATION,
    label: 'Non-Profit Organization',
  },
  { value: OrganizationType.OTHER, label: 'Other' },
];

export const ContactType = {
  UNDEFINED: 'UNDEFINED',
  WEBSITE: 'WEBSITE',
  EMAIL: 'EMAIL',
  FACEBOOK: 'FACEBOOK',
  FACEBOOK_MESSENGER: 'FACEBOOK_MESSENGER',
  FAX: 'FAX',
  INSTAGRAM: 'INSTAGRAM',
  PAGER: 'PAGER',
  PHONE: 'PHONE',
  SPYUR: 'SPYUR',
  TELEGRAM: 'TELEGRAM',
  TWITTER: 'TWITTER',
  VIBER: 'VIBER',
  WHATSAPP: 'WHATSAPP',
  OTHER: 'OTHER',
};

export const ContactTypeOptions = [
  { value: ContactType.UNDEFINED, label: 'Undefined' },
  { value: ContactType.WEBSITE, label: 'Website' },
  { value: ContactType.EMAIL, label: 'Email' },
  { value: ContactType.PHONE, label: 'Phone' },
  { value: ContactType.FACEBOOK, label: 'Facebook' },
  { value: ContactType.FACEBOOK_MESSENGER, label: 'Facebook messenger' },
  { value: ContactType.FAX, label: 'Fax' },
  { value: ContactType.INSTAGRAM, label: 'Instagram' },
  { value: ContactType.PAGER, label: 'Pager' },
  { value: ContactType.SPYUR, label: 'Spyur' },
  { value: ContactType.TELEGRAM, label: 'Telegram' },
  { value: ContactType.TWITTER, label: 'Twitter' },
  { value: ContactType.VIBER, label: 'Viber' },
  { value: ContactType.WHATSAPP, label: 'Whatsapp' },
  { value: ContactType.OTHER, label: 'Other' },
];

export const BookCondition = {
  UNDEFINED: 'UNDEFINED',
  NEW: 'NEW',
  LIKE_NEW: 'LIKE_NEW',
  VERY_GOOD: 'VERY_GOOD',
  GOOD: 'GOOD',
  FAIR: 'FAIR',
  POOR: 'POOR',
  DAMAGED: 'DAMAGED',
  MISSING_PAGES: 'MISSING_PAGES',
  WITHDRAWN: 'WITHDRAWN',
};

export const BookConditionOptions = [
  { value: BookCondition.UNDEFINED, label: 'Undefined' },
  { value: BookCondition.NEW, label: 'New' },
  { value: BookCondition.LIKE_NEW, label: 'Like new' },
  { value: BookCondition.VERY_GOOD, label: 'Very good' },
  { value: BookCondition.GOOD, label: 'Good' },
  { value: BookCondition.FAIR, label: 'Fair' },
  { value: BookCondition.POOR, label: 'Poor' },
  { value: BookCondition.DAMAGED, label: 'Damaged' },
  { value: BookCondition.MISSING_PAGES, label: 'Missing pages' },
];

export const Role = {
  UNDEFINED: 'UNDEFINED',
  ADMIN: 'ADMIN',
  LIBRARIAN: 'LIBRARIAN',
  USER: 'USER',
};

export const RoleOptions = [
  { value: Role.UNDEFINED, label: 'Undefined' },
  { value: Role.ADMIN, label: 'Admin' },
  { value: Role.LIBRARIAN, label: 'Librarian' },
  { value: Role.USER, label: 'User' },
];

export function isAdmin() {
  return localStorage.getItem('role') === Role.ADMIN;
}

export function canEditContent() {
  return isAdmin();
}

export function isLibrarian() {
  return localStorage.getItem('role') === Role.LIBRARIAN;
}

export const Occupation = {
  UNDEFINED: 'UNDEFINED',
  ADMIN: 'ADMIN',
  COORDINATOR: 'COORDINATOR',
  DONOR: 'DONOR',
  FOUNDER: 'FOUNDER',
  LIBRARIAN: 'LIBRARIAN',
  PRINCIPLE: 'PRINCIPLE',
  STUDENT: 'STUDENT',
  TEACHER: 'TEACHER',
  VOLUNTEER: 'VOLUNTEER',
};

export const OccupationOptions = [
  { value: Occupation.UNDEFINED, label: 'Undefined' },
  { value: Occupation.ADMIN, label: 'Admin' },
  { value: Occupation.COORDINATOR, label: 'Coordinator' },
  { value: Occupation.DONOR, label: 'Donor' },
  { value: Occupation.FOUNDER, label: 'Founder' },
  { value: Occupation.LIBRARIAN, label: 'Librarian' },
  { value: Occupation.PRINCIPLE, label: 'Principle' },
  { value: Occupation.STUDENT, label: 'Student' },
  { value: Occupation.TEACHER, label: 'Teacher' },
  { value: Occupation.VOLUNTEER, label: 'Volunteer' },
];

export const EmptyAddress = {
  id: '',
  street: '',
  streetAm: '',
  apartmentNumber: '',
  buildingNumber: '',
  city: '',
  cityAm: '',
  province: '',
  provinceAm: '',
  postalCode: '',
  country: '',
  countryAm: '',
  status: Status.NEW,
  createdDateTime: '',
  updatedDateTime: '',
};

export const EmptyOrganization = {
  id: '',
  name: '',
  nameAm: '',
  type: OrganizationType.UNDEFINED,
  description: '',
  descriptionAm: '',
  parentOrganization: null,
  alpParticipant: false,
  address: null,
  contacts: [],
  staffMembers: [],
  status: Status.NEW,
  createdDateTime: '',
  updatedDateTime: '',
};

export const EmptyPersonProfile = {
  id: '',
  firstName: '',
  lastName: '',
  firstNameAm: '',
  lastNameAm: '',
  occupation: Occupation.UNDEFINED,
  organization: null,
  gradeLevel: GradeLevel.UNDEFINED,
  alpParticipant: false,
  address: null, //{ ...EmptyAddress },
  contacts: [],
  status: Status.NEW,
  createdDateTime: '',
  updatedDateTime: '',
};

export const EmptyContact = {
  id: '',
  type: ContactType.UNDEFINED,
  value: '',
  status: Status.NEW,
  organization: null,
  personalProfile: null,
  createdDateTime: '',
  updatedDateTime: '',
};

export const createEmptyContact = (type) => ({
  id: '',
  type,
  value: '',
  status: Status.NEW,
  organization: null,
  personalProfile: null,
  createdDateTime: '',
  updatedDateTime: '',
});

export const InitialContactArray = [
  createEmptyContact(ContactType.WEBSITE),
  createEmptyContact(ContactType.EMAIL),
  createEmptyContact(ContactType.PHONE),
];

export const extractContactValues = (contacts) => {
  let websiteContact;
  let emailContact;
  let phoneContact;

  contacts.forEach((contact) => {
    switch (contact.type) {
      case ContactType.WEBSITE:
        websiteContact = contact;
        break;
      case ContactType.EMAIL:
        emailContact = contact;
        break;
      case ContactType.PHONE:
        phoneContact = contact;
        break;
      default:
        break;
    }
  });

  return { websiteContact, emailContact, phoneContact };
};

export const EmptyLibrary = {
  id: '',
  organization: { ...EmptyOrganization },
  name: '',
  description: '',
  address: { ...EmptyAddress },
  contacts: [],
  status: Status.NEW,
  createdDateTime: '',
  updatedDateTime: '',
};

export const EmptySeries = {
  id: '',
  title: '',
  titleAm: '',
  status: Status.NEW,
  createdDateTime: '',
  updatedDateTime: '',
};

export const EmptyPublisher = {
  id: '',
  name: '',
  nameAm: '',
  status: Status.NEW,
  createdDateTime: '',
  updatedDateTime: '',
};

export const EmptyEdition = {
  id: '',
  openLibraryId: '',
  openLibraryCoverId: 0,
  coverId: 0,
  externalCoverUrl: '',
  publisher: EmptyPublisher,
  publishDate: '',
  publishPlace: '',
  pageCount: 0,
  language: 'UNDEFINED',
  //works: [],
  title: '',
  subtitle: '',
  fullTitle: '',
  titleAm: '',
  subtitleAm: '',
  fullTitleAm: '',
  isbn10: '',
  isbn13: '',
  physicalFormat: 'UNDEFINED',
  gradeLevelStart: 'UNDEFINED',
  gradeLevelEnd: 'UNDEFINED',
  readingAgeStart: 0,
  readingAgeEnd: 0,
  //copies: [],
  status: Status.NEW,
  createdDateTime: '',
  updatedDateTime: '',
};

export const EmptyWork = {
  id: '',
  openLibraryId: '',
  title: '',
  subtitle: '',
  description: '',
  titleAm: '',
  subtitleAm: '',
  descriptionAm: '',
  authors: [],
  originalLanguage: 'UNDEFINED',
  volumeNumber: 0,
  series: EmptySeries,
  seriesItemNumber: 0,
  worldCat: '',
  status: Status.NEW,
  createdDateTime: '',
  updatedDateTime: '',
};

export const EmptyBook = {
  id: '',
  uuid: null,
  edition: { ...EmptyEdition },
  organization: { ...EmptyOrganization },
  callNumber: '',
  bookStatus: BookStatus.NEW,
  condition: BookCondition.UNDEFINED,
  dueDate: '',
  reader: null,
  notes: '',
  donation: null,
  starRating: StarRating.UNDEFINED,
  status: Status.NEW,
  createdDateTime: '',
  updatedDateTime: '',
};

export const EmptyDonationEvent = {
  id: '',
  name: '',
  nameAm: '',
  description: '',
  descriptionAm: '',
  organization: { ...EmptyOrganization },
  startDate: '',
  endDate: '',
  status: Status.NEW,
  createdDateTime: '',
  updatedDateTime: '',
};

export const EmptyShippingBox = {
  id: '',
  organization: { ...EmptyOrganization },
  donationEvent: { ...EmptyDonationEvent },
  sender: null,
  recipient: null,
  tag: '',
  trackingNumber: '',
  notes: '',
  shippingStatus: ShippingStatus.NEW,
  status: Status.NEW,
  createdDateTime: '',
  updatedDateTime: '',
};

export const EmptyDonation = {
  id: '',
  book: { ...EmptyBook },
  organization: { ...EmptyOrganization }, //book provider
  donationEvent: { ...EmptyDonationEvent },
  shippinBox: { ...EmptyShippingBox },
  donor: null,
  source: '',
  notes: '',
  donationStatus: DonationStatus.NEW,
  shippingStatus: ShippingStatus.NEW,
  status: Status.NEW,
  createdDateTime: '',
  updatedDateTime: '',
};

export const EmptyAuthor = {
  id: '',
  openLibraryId: '',
  name: '',
  personalName: '',
  firstName: '',
  lastName: '',
  nameAm: '',
  personalNameAm: '',
  firstNameAm: '',
  lastNameAm: '',
  birthDate: '',
  deathDate: '',
  bio: '',
  bioAm: '',
  status: Status.NEW,
  createdDateTime: '',
  updatedDateTime: '',
};

export const EmptyUser = {
  id: '',
  username: '',
  password: '',
  phone: '',
  email: '',
  role: Role.UNDEFINED,
  status: Status.NEW,
  createdDateTime: '',
  updatedDateTime: '',
};

export const EmptyWorkWithAuthors = {
  ...EmptyWork,
  authors: [{ ...EmptyAuthor }],
};

export const EmptyEditionWithWorks = {
  ...EmptyEdition,
  works: [{ ...EmptyWorkWithAuthors }],
};

export function getLabelByOptionValue(options, value) {
  const option = options.find((option) => option.value === value);
  return option ? option.label : value; // if label is not found return the raw value
}

export function getShippingBoxLabel(shippingBox) {
  if (!shippingBox?.id) {
    return 'UNDEFINED';
  }
  return shippingBox.tag
    ? `Shipping box ID: ${shippingBox.id} Tag: ${shippingBox.tag}`
    : `Shipping box ID: ${shippingBox.id}`;
}

export const ResourceName = {
  UNDEFINED: 'UNDEFINED',
  EDITION: 'EDITION',
  AUTHOR: 'AUTHOR',
  WORK: 'WORK',
  DONATION: 'DONATION',
  DONATION_EVENT: 'DONATION_EVENT',
  ORGANIZATION: 'ORGANIZATION',
  LIBRARY: 'LIBRARY',
  BOOK: 'BOOK',
  SERIES: 'SERIES',
  SHIPPING_BOX: 'SHIPPING_BOX',
  PERSON_PROFILE: 'PERSON_PROFILE',
  PUBLISHER: 'PUBLISHER',
};

export const getProfileName = (profile) => {
  if (profile?.name) {
    return profile.name.trim();
  }

  const firstName = profile?.firstName || '';
  const lastName = profile?.lastName || '';
  return `${firstName} ${lastName}`.trim();
};
