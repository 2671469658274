import React, { Component } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilSquare } from '@fortawesome/free-solid-svg-icons';
import {
  EmptyDonation,
  EmptyOrganization,
  ShippingStatusOptions,
  DonationStatusOptions,
  getLabelByOptionValue,
  canEditContent,
} from './Constant.js';
import {
  ViewTitle,
  BackButton,
  Pair,
  PairLink,
} from './utils/CustomViewUtil.js';
import { backgroundStyle } from './InlineStyles.js';
import { withTranslation } from 'react-i18next';
import { AppContext } from './AppContext.js';
import {
  getText,
  createTranslator,
  CaseFormat,
} from './utils/TranslationUtil.js';
import { getFullName } from './utils/TextUtil.js';

/* This is a higher order component that
 *  inject a special prop to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  }
  return ComponentWithRouter;
}

class DonationView extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    const { organization, donation } = this.props;
    this.state = {
      organization: organization || EmptyOrganization,
      donation: donation || EmptyDonation,
    };

    this.handleEditClick = this.handleEditClick.bind(this);
  }

  async componentDidMount() {
    var donation = this.props.location.state?.donation;
    // var organization = this.props.location.state?.organization;
    if (donation) {
      this.setState({
        organization: donation.organization,
        donation,
      });
    }
  }

  handleGoBack = () => {
    this.props.navigate(-1); // Use history.goBack() to navigate back
  };

  handleEditClick(event) {
    event.preventDefault();
    console.log('Edit clicked!');
    const { organization, donation } = this.state;
    this.props.navigate(`/donations/${donation.id}`, {
      state: { organization, donation, book: donation.book },
    });
  }

  render() {
    const { t } = this.props;
    const translate = createTranslator(t);
    const { appState, language } = this.context;
    const { donation, organization } = this.state;
    if (!donation) {
      return null;
    }

    const donationStatus = getLabelByOptionValue(
      DonationStatusOptions,
      donation.donationStatus
    );
    const shippingStatus = getLabelByOptionValue(
      ShippingStatusOptions,
      donation.shippingStatus
    );

    //const donorName = getProfileName(donation.donor);
    const donorName = getFullName(donation.donor);
    const donor = donorName ? <Pair label={'Donor'} value={donorName} /> : null;
    const donationEvent = donation.donationEvent;
    const source = donation.source ? (
      <Pair label={'Source'} value={donation.source} />
    ) : null;
    const book = donation.book;
    const orgName = getText(organization.name, organization.nameAm, language);

    return (
      <div>
        <div style={backgroundStyle}>
          <Container
            className='subview-container-blue'
            style={{ width: '50%', maxWidth: '600px' }}
          >
            <div style={{ position: 'relative', padding: '30px' }}>
              {/* Edit icon at the upper right corner */}
              {canEditContent() && (
                <div className='edit-pencil-container'>
                  <a href='' onClick={this.handleEditClick}>
                    <FontAwesomeIcon
                      icon={faPencilSquare}
                      className='edit-pencil-small'
                    />
                  </a>
                </div>
              )}
              <ViewTitle name={donation.id} resourceName={'Donation ID'} />
              <PairLink
                label={'Book'}
                link={
                  <Link
                    to={`/books/page/${book.id}`}
                    state={{ edition: book.edition }}
                    className='custom-green'
                  >
                    {book.edition.title}
                  </Link>
                }
              />
              <PairLink
                label={'Book provider'}
                link={
                  <Link
                    to={`/organizations/page/${organization.id}`}
                    state={{ organization }}
                    className='custom-green'
                  >
                    {orgName}
                  </Link>
                }
              />

              {donationEvent && (
                <PairLink
                  label={'Donation event'}
                  link={
                    <Link
                      to={`/donation-events/page/${donationEvent.id}`}
                      state={{ donationEvent }}
                      className='custom-green'
                    >
                      {donation.donationEvent.name}
                    </Link>
                  }
                />
              )}

              <Pair
                label={'Donation status'}
                value={translate(donationStatus, CaseFormat.CAPITALIZED)}
              />
              <Pair
                label={'Shipping status'}
                value={translate(shippingStatus, CaseFormat.CAPITALIZED)}
              />
              <p>{donor}</p>
              <p>{source}</p>
              <p>{donation.notes}</p>
              <p>
                <BackButton onClick={this.handleGoBack} />
              </p>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
const HOCDonationView = withRouter(withTranslation()(DonationView));
export default HOCDonationView;
