import React, { createContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ResourceName } from './Constant.js';
import { jwtDecode } from 'jwt-decode';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [appState, setAppState] = useState({
    searchQuery: '',
    selectedPage: 0,
    selectedResource: ResourceName.UNDEFINED,
    username: localStorage.getItem('username') || null,
  });

  const [language, setLanguage] = useState('en');
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === 'en' ? 'hy' : 'en'));
  };

  const updateAppSelectedPage = (selectedResource, selectedPage) => {
    setAppState((prevState) => ({
      ...prevState,
      selectedPage,
      selectedResource,
    }));
  };

  const login = (user, token, role) => {
    setAppState((prevState) => ({
      ...prevState,
      username: user.username,
    }));
    const decodedToken = jwtDecode(token);
    const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds

    localStorage.setItem('username', user.username);
    localStorage.setItem('role', role);
    localStorage.setItem('token', token);
    localStorage.setItem('tokenExpiration', expirationTime);
  };

  const logout = () => {
    setAppState((prevState) => ({
      ...prevState,
      username: null,
    }));
    localStorage.removeItem('username');
    localStorage.removeItem('role');
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiration');
    window.location.href = '/login';
  };

  const scheduleAutoLogout = () => {
    const tokenExpiration = localStorage.getItem('tokenExpiration');
    if (!tokenExpiration) {
      return;
    }
    const timeRemaining = tokenExpiration - Date.now();
    if (timeRemaining > 0) {
      setTimeout(() => {
        alert('Session expired. Logging out...');
        logout();
      }, timeRemaining);
    } else {
      logout();
    }
  };

  const checkTokenOnLoad = () => {
    const tokenExpiration = localStorage.getItem('tokenExpiration');
    if (!tokenExpiration) {
      return;
    }

    if (Date.now() > tokenExpiration) {
      alert('Session expired. Logging out...');
      logout();
    } else {
      scheduleAutoLogout();
    }
  };

  return (
    <AppContext.Provider
      value={{
        appState,
        setAppState,
        updateAppSelectedPage,
        login,
        logout,
        checkTokenOnLoad,
        language,
        toggleLanguage,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
