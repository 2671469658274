import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import {
  Button,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
} from 'reactstrap';
import {
  getSeriesById,
  postSeries,
  deleteSeries,
  isValidApiResponse,
} from './OpenGradaranApi.js';
import { EmptySeries } from './Constant.js';
import { backgroundStyle, inputDisableStyle } from './InlineStyles.js';
import {
  FormTitle,
  DeleteButton,
  CloseButton,
  SaveButton,
  StatusFormGroup,
} from './utils/CustomViewUtil.js';
import { useTranslation } from 'react-i18next';

const SeriesForm = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [series, setSeries] = useState({ ...EmptySeries });

  useEffect(() => {
    const fetchSeries = async () => {
      if (id !== 'new') {
        const fetchedSeries = await (await getSeriesById(id)).json();
        setSeries(fetchedSeries);
      }
    };

    fetchSeries();
  }, [id]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSeries((prevSeries) => ({
      ...prevSeries,
      [name]: value,
    }));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default behavior of the "Enter" key
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const seriesResponse = await (await postSeries(series)).json();
    if (isValidApiResponse(seriesResponse)) {
      navigate('/series');
    } else {
      // report error
    }
  };

  const handleClose = (event) => {
    console.log('Close clicked!');
    event.preventDefault();
    navigate(-1);
  };

  const handleDelete = async (event) => {
    event.preventDefault();

    // Display a confirmation dialog before proceeding with the delete action
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this series?'
    );

    if (!isConfirmed) {
      // If the user cancels, exit the function
      return;
    }

    if (series.id) {
      try {
        const seriesResponse = await deleteSeries(series.id);
        if (isValidApiResponse(seriesResponse)) {
          navigate('/series');
        } else {
          // Report invalid API response
          console.error('Invalid API response');
        }
      } catch (error) {
        // Handle network or other errors
        console.error('Error deleting series:', error);
      }
    } else {
      navigate(-1);
    }
  };

  return (
    <div>
      <div style={backgroundStyle}>
        <Container className='green-white'>
          <div className='form-container'>
            <FormTitle
              id={series.id}
              name={series.title}
              resourceName={'Series'}
            />
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor='id'>ID</Label>
                    <Input
                      type='text'
                      name='id'
                      id='id'
                      value={series.id}
                      onChange={handleChange}
                      autoComplete='name'
                      readOnly
                      style={inputDisableStyle}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor='title'>{t('Series Title')}</Label>
                    <Input
                      type='text'
                      name='title'
                      id='title'
                      value={series.title}
                      required
                      placeholder='enter series title'
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      autoComplete='name'
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor='titleAm'>
                      {t('Series Title')} / {t('in Armenian')}
                    </Label>
                    <Input
                      type='text'
                      name='titleAm'
                      id='titleAm'
                      value={series.titleAm}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      autoComplete='titleAm'
                    />
                  </FormGroup>
                </Col>
              </Row>
              <StatusFormGroup
                status={series.status}
                handleChange={handleChange}
              />
              <FormGroup>
                <SaveButton />
                <CloseButton onClick={handleClose} />
                <DeleteButton onClick={handleDelete} />
              </FormGroup>
            </Form>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default SeriesForm;
