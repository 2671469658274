import React from 'react';
import MapWithMarkers from './MapWithMarkers.js';
import { useTranslation } from 'react-i18next';

const ArmeniaMapWithLibraries = () => {
  const places = [
    {
      id: 1,
      name: 'Place 1',
      lat: 40.1772,
      lng: 44.50349,
      description: 'Description 1',
    },
    {
      id: 2,
      name: 'Place 2',
      lat: 40.7942,
      lng: 43.84528,
      description: 'Description 2',
    },
    // Add more places as needed
  ];

  return (
    <div>
      <h1>Clickable GPS Places Map</h1>
      <MapWithMarkers places={places} />
    </div>
  );
};

export default ArmeniaMapWithLibraries;
