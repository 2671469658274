// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

// Import translations
import translationEN from './locales/en/translation.json';
import translationHY from './locales/hy/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  hy: {
    translation: translationHY,
  },
};

i18n
  .use(Backend) // Load translations using http (default public/assets/locals/en/translations)
  .use(LanguageDetector) // Detect user language
  .use(initReactI18next) // Pass the i18n instance to react-i18next
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en', // Use English as fallback language
    debug: true,
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export default i18n;
