import React, { Component } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { Container, Form, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import {
  postContact,
  deleteContact,
  isValidApiResponse,
} from './OpenGradaranApi.js';
import {
  EmptyOrganization,
  EmptyPersonProfile,
  EmptyContact,
  ContactTypeOptions,
  ContactType,
} from './Constant.js';
import { backgroundStyle, inputDisableStyle } from './InlineStyles.js';
import {
  DeleteButton,
  CloseButton,
  SaveButton,
  SubFormTitle,
  StatusFormGroup,
} from './utils/CustomViewUtil.js';
import { withTranslation } from 'react-i18next';
import { getFullName } from './utils/TextUtil.js';

/* This is a higher order component that
 *  inject a special prop to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  }
  return ComponentWithRouter;
}

class ContactForm extends Component {
  constructor(props) {
    super(props);

    const { organization, personProfile, contact } = this.props;
    this.state = {
      organization: organization || EmptyOrganization,
      personProfile: personProfile || EmptyPersonProfile,
      contact: contact || EmptyContact,
      isLoading: false, // Loading state for API calls
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { organization, contact, personProfile } = this.props;
    if (
      organization !== prevProps.organization ||
      contact !== prevProps.contact ||
      personProfile !== prevProps.personProfile
    ) {
      this.setState({ organization, personProfile, contact });
    }
  }

  async componentDidMount() {
    var contact = this.props.location.state?.contact;
    var organization = this.props.location.state?.organization;
    var personProfile = this.props.location.state?.personProfile;
    if (organization && contact) {
      this.setState({
        organization,
        contact,
      });
    } else if (personProfile && contact) {
      this.setState({
        personProfile,
        contact,
      });
    }
  }

  handleKeyDown = (event) => {
    if (event.key == 'Enter') {
      event.preventDefault(); // Prevent the default behavior of the "Enter" key
    }
  };

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let contact = { ...this.state.contact };
    contact[name] = value;
    this.setState({ contact });
  }

  async handleDelete(event) {
    event.preventDefault();

    // Display a confirmation dialog before proceeding with the delete action
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this contact?'
    );

    if (!isConfirmed) {
      // If the user cancels, exit the function
      return;
    }

    const { contact } = this.state;
    if (contact.id) {
      try {
        var contactResponse = await (await deleteContact(contact.id)).json();
        if (isValidApiResponse(contactResponse)) {
          this.props.navigate(-1);
        } else {
          //report error
        }
      } catch (error) {
        // Handle network or other errors
        console.error('Error deleting contact:', error);
      }
    } else {
      this.props.navigate(-1);
    }
  }

  handleClose(event) {
    console.log('Close clicked!');
    event.preventDefault();
    this.props.navigate(-1);
  }

  async handleSubmit(event) {
    event.preventDefault();
    const { organization, personProfile, contact } = this.state;

    contact.organization = organization;
    contact.personProfile = personProfile;
    if (contact.type !== ContactType.UNDEFINED && contact.value !== '') {
      var contactResponse = await (await postContact(contact)).json();
      if (isValidApiResponse(contactResponse)) {
        this.props.navigate(-1);
      } else {
        //report error
      }
    }
  }

  render() {
    const { t } = this.props;
    const { organization, personProfile, contact, isLoading } = this.state;
    if (!contact) {
      return null;
    }
    const organizationTitle = organization.id ? (
      <SubFormTitle name={organization.name} resourceName={'Organization'} />
    ) : null;

    const personTitle = personProfile.id ? (
      <SubFormTitle
        name={getFullName(personProfile)}
        resourceName={'Person Profile'}
      />
    ) : null;

    return (
      <div style={backgroundStyle}>
        <Container className='subform-container'>
          <Row>
            {organizationTitle}
            {personTitle}
            <Form onSubmit={this.handleSubmit}>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor='id'>Contact ID</Label>
                    <Input
                      type='text'
                      name='id'
                      id='id'
                      value={contact.id}
                      onChange={this.handleChange}
                      readOnly
                      style={inputDisableStyle}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor='type'>Contact Type</Label>
                    <select
                      value={contact.type}
                      defaultValue={ContactType.EMAIL}
                      onChange={this.handleChange}
                      className='form-control'
                      name='type'
                      id='type'
                    >
                      {ContactTypeOptions.map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </select>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor='value'>Contact Value</Label>
                    <Input
                      type='text'
                      name='value'
                      id='value'
                      value={contact.value}
                      onChange={this.handleChange}
                      onKeyDown={this.handleKeyDown}
                      autoComplete='name'
                    />
                  </FormGroup>
                </Col>
              </Row>
              <StatusFormGroup
                status={contact.status}
                handleChange={this.handleChange}
              />
              <FormGroup>
                <SaveButton />
                <CloseButton onClick={this.handleClose} />
                <DeleteButton onClick={this.handleDelete} />
              </FormGroup>
            </Form>
          </Row>
        </Container>
      </div>
    );
  }
}
const HOCContactForm = withRouter(withTranslation()(ContactForm));
export default HOCContactForm;
