import React from 'react';

export const formatDateShort = (date, language = 'en') => {
  const locale = getLocale(language);
  return new Date(date).toLocaleDateString(locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

export const formatDate = (dateTimeString, language = 'en') => {
  const locale = getLocale(language);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateTimeString).toLocaleString(locale, options);
};

export const formatDateMonthYear = (dateTimeString, language = 'en') => {
  const locale = getLocale(language);
  const options = { year: 'numeric', month: 'long' };
  return new Date(dateTimeString).toLocaleString(locale, options);
};

const getLocale = (language) => {
  return language === 'en' ? 'en-US' : 'hy';
};
