import React from 'react';
import * as XLSX from 'xlsx';
import {
  isValidApiResponse,
  getEdition,
  getOrganization,
  getPersonProfile,
  getDonationEvent,
  getArmenianLibraryProject,
  postBook,
  postDonation,
} from './OpenGradaranApi.js';
import { parseISBN } from './OpenLibraryConverter.js';
import { Container } from 'reactstrap';
import { backgroundStyle } from './InlineStyles.js';
import { withTranslation } from 'react-i18next';
import Papa from 'papaparse';
import { EmptyDonation, EmptyBook } from './Constant.js';

const containerStyle = {
  width: '30%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center', // center items horizontally
};

class BookGenerator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      excelData: [],
      isLoading: false, // Loading state for API calls
    };
  }

  handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0]; // Assuming we're interested in the first sheet
      const sheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      var isLoading = true;
      this.setState({ excelData, isLoading });

      const mappingLogs = [];

      for (let i = 1; i < excelData.length; i++) {
        //for (let i = 1; i < 10; i++) {
        const row = excelData[i];
        // Assuming ISBN is in the third column (index 2)
        const author = row[0];
        const isbn = parseISBN(row[1]);
        const title = row[2];
        const alpTitle = row[3];
        const misc = row[4];
        const error = ''; //row[5];
        const uuid = row[6];
        const editionId = row[7];
        const donorId = row[8];
        const schoolId = row[9];
        const alpSchoolId = row[10];
        const donationEventId = row[11];
        const donationStatus = row[12];

        var edition = null;
        var donor = null;
        var donation = null;
        var book = null;
        var organization = null;
        var donationEvent = null;
        var errorMsg = '';
        var bookId = 0;
        var donationId = 0;
        var alp = null;

        const alpResponse = await (await getArmenianLibraryProject()).json();
        if (alpResponse.message == null) {
          const page = alpResponse.data.page;
          if (page?.length) {
            alp = page[0];
          }
        } else {
          // handle errors
          console.error('Error fetching data:', alpResponse.message);
        }

        if (editionId === 0) {
          errorMsg = `Edition id is 0`;
          console.error(errorMsg);
          continue;
        } else {
          const editionResp = await (await getEdition(editionId)).json();
          if (isValidApiResponse(editionResp)) {
            edition = editionResp;
          } else {
            // handle error
          }
        }

        if (alpSchoolId === 0) {
          errorMsg = `School id is 0`;
          console.error(errorMsg);
        } else {
          const organizationResp = await (
            await getOrganization(alpSchoolId)
          ).json();
          if (isValidApiResponse(organizationResp)) {
            organization = organizationResp;
          } else {
            // handle error
          }
        }

        if (donorId === 0) {
          errorMsg = `Donor id is 0`;
          console.error(errorMsg);
        } else {
          const donorResp = await (await getPersonProfile(donorId)).json();
          if (isValidApiResponse(donorResp)) {
            donor = donorResp;
          } else {
            // handle error
          }
        }

        if (donationEventId === 0) {
          errorMsg = `Donation event id is 0`;
          console.error(errorMsg);
        } else {
          const donationEventResp = await (
            await getDonationEvent(donationEventId)
          ).json();
          if (isValidApiResponse(donationEventResp)) {
            donationEvent = donationEventResp;
          } else {
            // handle error
          }
        }
        // post book
        var book = { ...EmptyBook };
        book.edition = edition;
        book.organization = organization;
        book.uuid = uuid;

        var bookResponse = await (await postBook(book)).json();
        if (isValidApiResponse(bookResponse)) {
          bookId = bookResponse.id;
        } else {
          //report error
          errorMsg = `Error posting book with editionId = ${editionId} error: ${bookResponse.message}`;
        }

        if (bookId > 0) {
          // post donation
          var donation = { ...EmptyDonation };
          donation.book = bookResponse;
          donation.organization = alp;
          donation.donationEvent = donationEvent;
          donation.donor = donor;
          donation.donationStatus = donationStatus;

          var donationResponse = await (await postDonation(donation)).json();
          if (isValidApiResponse(donationResponse)) {
            donationId = donationResponse.id;
          } else {
            //report error
            errorMsg = `Error posting donation for bookId = ${bookId} error: ${donationResponse.message}`;
          }
        }

        mappingLogs.push({
          author,
          isbn,
          alpTitle,
          editionId,
          error: errorMsg,
          uuid,
          bookId,
          donationId,
          schoolId,
          donorId,
          donationEventId,
          donationStatus,
          misc,
        });
      }

      if (mappingLogs.length > 0) {
        const csv = Papa.unparse(mappingLogs);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'book_mapping_logs.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      isLoading = false;
      this.setState({ excelData, isLoading });
    };

    reader.readAsArrayBuffer(file);
  };

  render() {
    const { t } = this.props;
    let loadingView = null;
    if (this.state.isLoading) {
      loadingView = <p>Loading...</p>;
    }
    return (
      <div style={backgroundStyle}>
        <Container className='subform-container' style={containerStyle}>
          <div>
            <input type='file' onChange={this.handleFileUpload} />
          </div>
          <div>{loadingView}</div>
        </Container>
      </div>
    );
  }
}

export default withTranslation()(BookGenerator);
