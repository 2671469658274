import React, { Component } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { Button, Container } from 'reactstrap';
import { getShippingBox } from './OpenGradaranApi.js';
import {
  EmptyShippingBox,
  ShippingStatusOptions,
  getLabelByOptionValue,
  canEditContent,
} from './Constant.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilSquare } from '@fortawesome/free-solid-svg-icons';
import { ViewTitle, ViewSubtitle, BackButton } from './utils/CustomViewUtil.js';
import { backgroundStyle } from './InlineStyles.js';
import { withTranslation } from 'react-i18next';
import { AppContext } from './AppContext.js';
import { getFullName } from './utils/TextUtil.js';

/* This is a higher order component that
 *  inject a special prop to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  }
  return ComponentWithRouter;
}

class ShippingBoxPage extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);

    this.state = {
      shippingBox: { ...EmptyShippingBox },
      // organization: { ...EmptyOrganization },
      // donationEvent: { ...EmptyDonationEvent },
    };
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  async componentDidMount() {
    const id = this.props.params.id;
    var shippingBox = this.props.location.state?.shippingBox;
    if (!shippingBox && id) {
      const shippingBoxResp = await (await getShippingBox(id)).json();
      if (shippingBoxResp.message == null) {
        shippingBox = shippingBoxResp;
      } else {
        // handle error
      }
    }
    this.setState({ shippingBox: shippingBox || EmptyShippingBox });
  }

  handleEditClick(event) {
    event.preventDefault();
    console.log('Icon clicked!');
    const { shippingBox } = this.state;
    this.props.navigate(`/shipping-boxes/${shippingBox.id}`, {
      state: {
        organization: shippingBox.organization,
        donationEvent: shippingBox.donationEvent,
        shippingBox,
      },
    });
  }

  handleGoBack = () => {
    this.props.navigate(-1); // Use history.goBack() to navigate back
  };

  render() {
    const { t } = this.props;
    const { appState } = this.context;
    const { organization, donationEvent, shippingBox } = this.state;

    const shippingStatus = getLabelByOptionValue(
      ShippingStatusOptions,
      shippingBox.shippingStatus
    );

    const donationsLink = (
      <Link
        to={`/shipping-boxes/${shippingBox.id}/donations`}
        state={{ organization, donationEvent, shippingBox }}
        className='custom-link-green'
      >
        {t('Donations')}
      </Link>
    );

    return (
      <div>
        <div style={backgroundStyle}>
          <Container className='subview-container-blue'>
            <div style={{ position: 'relative', padding: '30px' }}>
              {/* Edit icon at the upper right corner */}
              {canEditContent() && (
                <div className='edit-pencil-container'>
                  <a href='' onClick={this.handleEditClick}>
                    <FontAwesomeIcon
                      icon={faPencilSquare}
                      className='edit-pencil-small'
                    />
                  </a>
                </div>
              )}
              <ViewTitle
                name={shippingBox.id}
                resourceName={'Shipping Box ID'}
              />
              <ViewSubtitle
                name={shippingBox.organization?.name}
                resourceName={'Book Provider'}
              />
              <ViewSubtitle
                name={shippingBox.donationEvent?.name}
                resourceName={'Donation Event'}
              />
              <p>Tracking number: {shippingBox.trackingNumber}</p>
              <p>Shipping Status: {shippingStatus}</p>
              <p>Sender: {getFullName(shippingBox.sender)}</p>
              <p>Recipient: {getFullName(shippingBox.recipient)}</p>
              <p>{shippingBox.notes}</p>
              <p>{donationsLink}</p>
              <p>
                <BackButton onClick={this.handleGoBack} />
              </p>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
const HOCShippingBoxPage = withRouter(withTranslation()(ShippingBoxPage));
export default HOCShippingBoxPage;
