import React, { Component } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Button, Container, Form, FormGroup, Input, Label } from 'reactstrap';
import { getEditionsByIsbn, isValidApiResponse } from './OpenGradaranApi.js';
import { backgroundStyle } from './InlineStyles.js';
import { parseISBN } from './OpenLibraryConverter.js';
import { canEditContent } from './Constant.js';

/* This is a higher-order component that
 * injects special props to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouter;
}

class IsbnReader extends Component {
  state = {
    isbn: '',
  };

  constructor(props) {
    super(props);
    this.handleIsbnChange = this.handleIsbnChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBarcodeInput = this.handleBarcodeInput.bind(this);
    this.buffer = ''; // Buffer to accumulate barcode scanner input
  }

  componentDidMount() {
    // Listen for barcode scanner input
    window.addEventListener('keypress', this.handleBarcodeInput);
  }

  componentWillUnmount() {
    // Cleanup the event listener
    window.removeEventListener('keypress', this.handleBarcodeInput);
  }

  handleBarcodeInput(event) {
    if (event.key === 'Enter') {
      if (this.buffer) {
        const parsedISBN = parseISBN(this.buffer);
        this.setState({ isbn: parsedISBN }, () => {
          this.handleSubmit(new Event('submit'));
        });
        this.buffer = ''; // Reset the buffer
      }
    } else if (/^[a-zA-Z0-9]+$/.test(event.key)) {
      this.buffer += event.key;
    }
  }

  handleIsbnChange(event) {
    const value = event.target.value;
    this.setState({ isbn: value }); // Manual input updates the state directly
  }

  async handleSubmit(event) {
    event.preventDefault();
    const isbn = this.state.isbn;

    const editionPageResponse = await (await getEditionsByIsbn(isbn)).json();

    if (
      (isValidApiResponse(editionPageResponse) &&
        editionPageResponse.data.page.length > 0) ||
      !canEditContent()
    ) {
      this.props.navigate(`/editions/search/${isbn}`);
    } else {
      this.props.navigate('/isbn/' + isbn);
    }
  }

  render() {
    const { isbn } = this.state;
    return (
      <div>
        <div style={backgroundStyle}>
          <Container className='subform-container' style={{ width: '30%' }}>
            <Form onSubmit={this.handleSubmit}>
              <FormGroup>
                <Label htmlFor='isbn'>ISBN</Label>
                <Input
                  color='primary'
                  type='text'
                  name='isbn'
                  id='isbn'
                  value={isbn}
                  required
                  placeholder='Scan or enter ISBN'
                  onChange={this.handleIsbnChange}
                  autoComplete='off'
                />
              </FormGroup>

              <FormGroup>
                <Button color='primary' type='submit'>
                  Submit
                </Button>{' '}
                <Button color='secondary' tag={Link} to='/'>
                  Cancel
                </Button>
              </FormGroup>
            </Form>
          </Container>
        </div>
      </div>
    );
  }
}

const HOCIsbnReader = withRouter(IsbnReader);
export default HOCIsbnReader;
